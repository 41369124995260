import React, {useState} from "react";
import {connect} from "react-redux";
import {IStore} from "../redux/defaultStore";
import {Button, Card, CardBody, Container} from "reactstrap";
import FinancialSummaryComponent from "../Components/FinancialSummaryComponent";
import PurchaseSecuritiesFromMarketModal from "../Components/PurchaseSecuritiesFromMarketModal";

interface IPurchaseSecuritiesPageProps {
	token?: string;
	dispatch?: any;
}

const PurchaseSecuritiesPage: React.FC<IPurchaseSecuritiesPageProps> = (props: IPurchaseSecuritiesPageProps) => {

	const {token} = props;
	const [showModal, setShowModal] = useState(false);

	function toggleModal(): void {
		setShowModal(!showModal);
	}

	return (
		<React.Fragment>
			<PurchaseSecuritiesFromMarketModal
				isOpen={showModal}
				onClose={toggleModal}
			/>

			<Container className="my-5 md-fluid-container">
				<Card>
					<CardBody>
						<h2>Purchase Securities Directly From The Market</h2>
						<p>
							This is where you can use your available funds to purchase securities directly from the
							market, thus creating more supply.
							When you make a purchase, you will receive your chosen quantity in both above & below market
							value of the selected security.
							For example, if you choose to buy 1,000 of a security, you will receive 1,000 of that
							security above market value, and 1,000 below.
							When your purchase is completed, the funds will be removed from your account and the
							securities added to your portfolio, viewable on the Holdings page.
							<br/>
							<br/>
							To purchase securities click the button below to open the form.
						</p>

						<div className="d-flex justify-content-center my-4">
							<Button color="materialBlue" onClick={toggleModal}>
								Purchase Securities From Market
							</Button>
						</div>
					</CardBody>
				</Card>
			</Container>
		</React.Fragment>
	);
};

export default connect((store: IStore, props: IPurchaseSecuritiesPageProps) => {
	return {
		token: store.metaStore.token,
		...props,
	}
})(PurchaseSecuritiesPage);
