import React, {ReactNode, useEffect, useState} from "react";
import {Card, CardBody, Col, Container, Row} from "reactstrap";
import {connect} from "react-redux";
import {IStore} from "../redux/defaultStore";
import {addError, decrementLoading, incrementLoading} from "../redux/meta/MetaActions";
import {Article, RealestateSecuritiesApi} from "client";
import NewsFeedArticleCard from "../Components/NewsFeedArticleCard";
import {getConfig} from "../services/clientApis";

interface INewsFeedProps {
	token?: string;
	dispatch?: any;
}

const NewsFeed: React.FC<INewsFeedProps> = (props: INewsFeedProps) => {

	const {token} = props;
	const [articles, setArticles] = useState<Array<Article>>([]);

	useEffect(() => {
		readNewsFeed().then().catch();
	}, []);

	/**
	 * Call api and get the news feed articles
	 *
	 */
	async function readNewsFeed(): Promise<void> {
		props.dispatch(incrementLoading());

		try {
			const res = await new RealestateSecuritiesApi(getConfig(token)).realestateSecuritiesNewsFeedGet({});
			setArticles(res);
		} catch (e) {
			props.dispatch(addError(await e.json()));
		}

		props.dispatch(decrementLoading());
	}

	/**
	 * Iterate the articles and return a component for each
	 *
	 * @param as
	 */
	function mapArticles(as: Array<Article> = []): ReactNode {
		return as.map((a: Article, i: number) => {
			const hero = i === 0;
			return (
				<Col xs={12} sm={12} md={hero ? 12 : 6} lg={hero ? 12 : 6} key={`news-feed-article-${i}`} className="mb-4">
					<NewsFeedArticleCard article={a} hero={hero}/>
				</Col>
			)
		});
	}

	return (
		<Container className="my-5">
			<Row className="flex-wrap">
				{mapArticles(articles)}
			</Row>
		</Container>
	);
};

export default connect((store: IStore, props: INewsFeedProps) => {
	return {
		token: store.metaStore.token,
		...props,
	}
})(NewsFeed);
