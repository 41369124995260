import React, {ChangeEventHandler, useEffect, useState} from "react";
import {connect} from "react-redux";
import {IStore} from "../redux/defaultStore";
import {Button, Col, Input, Label, Modal, ModalBody, ModalFooter, ModalHeader, Row} from "reactstrap";
import SelectOptions, {ISelectOptions} from "./SelectOptions";
import NumberFormat from "react-number-format";
import {formatCurrency, numberWithCommas} from "../utils/formatters";
import {addError, addToast, decrementLoading, incrementLoading} from "../redux/meta/MetaActions";
import {
	MarketApi,
	MarketGetSecuritiesForMarketGetIsActiveEnum,
	MarketIdentifier,
	Portfolio,
	PortfolioSecurity,
	Security
} from "client";
import {getConfig} from "../services/clientApis";
import findIndex from "lodash.findindex";
import {ToastType} from "./ToastManager";
import {updateSidebarStandings} from "../redux/financialStanding/FinancialStandingActions";

interface IPostSellOfferModalProps {
	token?: string;
	dispatch?: any;
	isOpen: boolean;

	onCancel(): void;
	onDone(): void;
}

const defaultForm: any = {
	security: -1 as any,
	expectedTrue: true,
	quantity: "" as any,
	pricePerSecurity: "" as any,
};

const PostSellOfferModal: React.FC<IPostSellOfferModalProps> = (props: IPostSellOfferModalProps) => {

	const {token, dispatch, isOpen} = props;
	const [form, setForm] = useState(defaultForm);
	const [securities, setSecurities] = useState<Array<Security>>([]);
	const [portfolio, setPortfolio] = useState<Portfolio>();

	useEffect(() => {
		if (isOpen) {
			readSecuritiesAndPortfolio().then().catch();
		}
	}, [isOpen]);

	/**
	 * Reset the form values and close the modal
	 *
	 */
	function resetAndClose(): void {
		setForm(defaultForm);
		props.onCancel();
	}

	/**
	 * read list of securities in the currently selected market,
	 * as well as getting the user's portfolio to calculate the values
	 * displayed on the bottom of the form
	 *
	 */
	async function readSecuritiesAndPortfolio(): Promise<void> {
		props.dispatch(incrementLoading());

		try {
			const res = await new MarketApi(getConfig(token)).marketGetSecuritiesForMarketGet({
				isActive: MarketGetSecuritiesForMarketGetIsActiveEnum.True,
				marketIdentifier: MarketIdentifier.RealEstateSecuritiesPlayMoneyVancouver,
			});

			setSecurities(res);
		} catch (e) {
			props.dispatch(addError(await e.json()));
			props.dispatch(decrementLoading());
			return;
		}

		try {
			const portfolioRes = await new MarketApi(getConfig(token)).marketGetPortfolioGet({marketIdentifier: MarketIdentifier.RealEstateSecuritiesPlayMoneyVancouver});

			setPortfolio(portfolioRes);
		} catch (e) {
			props.dispatch(addError(await e.json()))
		}

		props.dispatch(decrementLoading());
	}

	/**
	 * onChange for standard input fields
	 *
	 * @param key
	 */
	function createOnChange(key: keyof any): ChangeEventHandler<HTMLInputElement> {
		return (e) => {
			setForm({
				...form,
				[key]: e.target.value,
			});
		}
	}

	/**
	 * logic for handling above/below, represented as 1/0, saved as true/false
	 *
	 * @param e
	 */
	function createOnExpectedTrueChange(e): void {
		setForm({
			...form,
			expectedTrue: e.target.value == 1,
		});
	}

	/**
	 * find the index of the selected security from the
	 * drop down and then select the correct security
	 *
	 * @param e
	 */
	function onSecurityChange(e): void {
		const foundSecurity: number = findIndex(securities, {_id: e.target.value});
		setForm({
			...form,
			security: securities[foundSecurity],
		});
	}

	/**
	 * onChange for quantity input,
	 * limit maximum to 100 million
	 *
	 * @param e
	 */
	function onQuantityChange(e): void {
		let v = e.floatValue;
		if (v > 100000000) {
			v = 100000000;
		}

		setForm({
			...form,
			quantity: v,
		});
	}

	/**
	 * onChange for price input,
	 * limit maximum to 1
	 *
	 * @param e
	 */
	function onPriceChange(e): void {
		let v = e.floatValue;
		if (v > 1) {
			v = 1.00;
		}

		setForm({
			...form,
			pricePerSecurity: v,
		})
	}

	/**
	 * Call the api to submit the sell order
	 *
	 */
	async function submitNewSellOrder(): Promise<void> {
		props.dispatch(incrementLoading());

		try {
			const res = await new MarketApi(getConfig(token)).marketMakeOrderPost({
				makeOrderRequest: {
					security: form.security,
					expectedTrue: form.expectedTrue,
					pricePerSecurity: form.pricePerSecurity,
					quantity: Math.round(form.quantity * parseFloat(process.env.REACT_APP_SECURITY_MULTIPLIER)),
					buyOrder: false,
				},
			});

			setForm(defaultForm);
			props.dispatch(addToast(ToastType.POSTED_SELL, Math.round(form.quantity * parseFloat(process.env.REACT_APP_SECURITY_MULTIPLIER))));
			props.dispatch(updateSidebarStandings());
			props.onDone();

		} catch (e) {
			props.dispatch(addError(await e.json()))
		}

		props.dispatch(decrementLoading());
	}

	/**
	 * calculate the total amount of the selected security that is already owned
	 * to be displayed in the bottom part of the form.
	 *
	 * Accounts for expectedTrue & counts both owned & invested of that security type
	 *
	 */
	let amountOfSelectedSecurityOwned: number = 0;
	if (portfolio && form.security as any !== -1) {
		const ownedSelectedSecurity: PortfolioSecurity = portfolio.securities[form.security._id];
		amountOfSelectedSecurityOwned = form.expectedTrue ? (ownedSelectedSecurity.trueOwned) : (ownedSelectedSecurity.falseOwned);
	}

	return (
		<Modal
			isOpen={isOpen}
			fade={true}
			centered={true}
			contentClassName="px-3"
			className="modal-max-600"
		>
			<ModalHeader toggle={resetAndClose}>
				Post Sell Order
			</ModalHeader>

			{portfolio && (
				<ModalBody>
					<form>
						<div className="mb-3">
							<Label for="security">Select Security</Label>
							<Input type="select" id="security" name="security" placeholder="Select Security"
							       onChange={onSecurityChange} value={form.security ? form.security._id : -1}>
								<option value="" disabled selected>Select Security</option>
								<hr/>
								<SelectOptions fullOptions={createSecuritySelectOptions(securities)}/>
							</Input>
						</div>

						<div className="mb-3">
							<Label for="marketValue">Market Value</Label>
							<Input type="select" id="marketValue" name="marketValue" placeholder="Market Value"
							       onChange={createOnExpectedTrueChange} value={form.expectedTrue ? 1 : 0}>
								<option value="" disabled selected className="text-muted">Select Above/Below</option>
								<hr/>
								<option value={1} className="text-muted">Above</option>
								<option value={0} className="text-muted">Below</option>
							</Input>
						</div>

						<div className="mb-3">
							<Label for="quantity">Enter Quantity</Label>
							<Row className="mb-3">
								<Col xs={12} sm={6} className="mb-2 mb-sm-0">
									<NumberFormat
										allowLeadingZeros={false}
										placeholder="Quantity"
										value={form.quantity}
										customInput={Input}
										thousandSeparator={true}
										decimalScale={0}
										onValueChange={onQuantityChange}
										allowNegative={false}
									/>
								</Col>
								<Col xs={12} sm={6}
								     className="d-flex align-items-center justify-content-center justify-content-sm-start">
									<p className="mb-0">
								<span className="text-muted">
									{`x 1,000 = `}
								</span>
										{form.quantity ? getQty(form.quantity, true) : ""}
									</p>
								</Col>
							</Row>
						</div>

						<div className="mb-3">
							<Label for="pricePer">Enter Price Per Security (maximum $1.00)</Label>
							<Row className="mb-3 d-flex align-items-center">
								<Col xs={6} sm={4} className="mb-2">
									<NumberFormat
										allowLeadingZeros={false}
										prefix="$"
										placeholder="0.00"
										value={form.pricePerSecurity}
										customInput={Input}
										thousandSeparator={true}
										decimalScale={2}
										onValueChange={onPriceChange}
										allowNegative={false}
									/>
								</Col>
							</Row>
						</div>
					</form>

					{doAllFormValuesExist(form) && (form.security as any !== -1) && (
						<div>
							<hr/>
							<h5 className="text-center skinny mb-3">
								{`Offering ${getQty(form.quantity, true)} x ${getExpectedTrueString(form.expectedTrue)}, '${form.security ? form.security.name : ""}' securities, for ${getInvestmentTotal(form, true)}`}
							</h5>

							<Row style={{fontSize: "11pt"}}>
								<Col xs={12} sm={6} className="mb-4 mb-sm-0">
									<div className="d-flex justify-content-between">
									<span className="mr-2">
										Balance before posting
									</span>
										<span>
										{formatCurrency(portfolio.availableMoney)}
									</span>
									</div>

									<div className="d-flex justify-content-between text-muted">
									<span className="mr-2">
										This sell order
									</span>
										<span className="border-bottom border-dark">
										+{getInvestmentTotal(form, true)}
									</span>
									</div>

									<div className="d-flex justify-content-between mt-2">
									<span className="mr-2">
										Balance after sell order completes
									</span>
									<span>
										{formatCurrency(portfolio.availableMoney + (getInvestmentTotal(form) as number))}
									</span>
									</div>
								</Col>

								<Col xs={12} sm={6}>
									<div className="d-flex justify-content-between">
									<span className="mr-2">
										{`Your quantity of ${getExpectedTrueStringLower(form.expectedTrue)} ${form.security ? form.security.name : ""} securities before posting`}
									</span>
									<span>
										{numberWithCommas(amountOfSelectedSecurityOwned)}
									</span>
									</div>

									<div className="d-flex justify-content-between text-muted">
									<span className="mr-2">
										This sell order
									</span>
										<span className="border-bottom border-dark">
										-{getQty(form.quantity, true)}
									</span>
									</div>

									<div className="d-flex justify-content-between mt-2">
									<span className="mr-2">
										{`Your quantity of ${getExpectedTrueStringLower(form.expectedTrue)} ${form.security ? form.security.name : ""} securities after posting`}
									</span>
									<span className={((amountOfSelectedSecurityOwned - (getQty(form.quantity) as number)) < 0) ? "text-danger" : ""}>
										{numberWithCommas(amountOfSelectedSecurityOwned - (getQty(form.quantity) as number))}
									</span>
									</div>
								</Col>
							</Row>
						</div>
					)}

				</ModalBody>
			)}

			<ModalFooter>
				<Button color="link" className="text-materialBlue" onClick={resetAndClose}>
					Cancel
				</Button>

				<Button color="materialBlue" className="ml-3" disabled={!doAllFormValuesExist(form)} onClick={submitNewSellOrder}>
					Confirm
				</Button>
			</ModalFooter>
		</Modal>
	);
};

/**
 * get quantity of the current offer being filled out (quantity x 1000)
 *
 * @param x
 * @param asString
 */
function getQty(x: number, asString: boolean = false): number | string {
	const q: number = Math.round(x * parseFloat(process.env.REACT_APP_SECURITY_MULTIPLIER));
	return asString ? numberWithCommas(q) : q;
}

/**
 * get the total price of the offer being filled out (getQty(y) x price)
 *
 * @param form
 * @param asString
 */
function getInvestmentTotal(form: any, asString: boolean = false): number | string {
	const t: number = form.pricePerSecurity * (getQty(form.quantity) as number);
	return asString ? formatCurrency(t) : t;
}

/**
 * let the form handle the values of the expectedTrue as just true/false or 0/1, etc.
 * helper function to determine string
 *
 * @param expected
 */
function getExpectedTrueString(expected: boolean): string {
	return expected ? "Above Market Value" : "Below Market Value";
}

function getExpectedTrueStringLower(expected?: boolean): string {
	return expected ? "above market value" : "below market value";
}

/**
 * utility for checking if buttons that rely on all values in a form
 * being entered should be disabled or not.
 *
 * @param f
 */
function doAllFormValuesExist(f: any = {}): boolean {
	if (Object.keys(f).length > 0) {
		for (let v in f) {
			if (f.hasOwnProperty(v)) {
				if (f[v] === undefined || !f[v].toString() || f[v].toString().length < 1) {
					return false;
				}
			}
		}
		return true;
	} else {
		return false;
	}
}

function createSecuritySelectOptions(securities: Array<Security>): Array<ISelectOptions> {
	return securities.map((security: Security, i: number) => {
		return {value: security._id, display: security.name};
	});
}

function createMarketValueSelectOptions(marketValues: Array<any>): Array<ISelectOptions> {
	return marketValues.map((m: any, i: number) => {
		return {value: i, display: JSON.stringify(m)};
	});
}

export default connect((store: IStore, props: IPostSellOfferModalProps) => {
	return {
		token: store.metaStore.token,
		...props,
	}
})(PostSellOfferModal);
