import React, {useEffect, useState} from "react";
import {Button, Col, Modal, ModalBody, ModalFooter, ModalHeader, Row} from "reactstrap";
import {connect} from "react-redux";
import {IStore} from "../redux/defaultStore";
import {MarketApi, MarketIdentifier, Order, OrderInformation} from "client";
import cloneDeep from "lodash.clonedeep";
import {formatCurrency, numberWithCommas, parseOrderTypeToString} from "../utils/formatters";
import {addError, decrementLoading, incrementLoading} from "../redux/meta/MetaActions";
import {getConfig} from "../services/clientApis";
import {updateSidebarStandings} from "../redux/financialStanding/FinancialStandingActions";

interface ICancelCurrentOrderModalProps {
	token?: string;
	dispatch?: any;
	orderInfo: OrderInformation;

	onCancel(): void;
	onDoneDeleting(): void;
}

const CancelCurrentOrderModal: React.FC<ICancelCurrentOrderModalProps> = (props: ICancelCurrentOrderModalProps) => {

	const {token, orderInfo} = props;
	const [dataClone, setDataClone] = useState<OrderInformation>();

	useEffect(() => {
		if (orderInfo && Object.keys(orderInfo).length > 0) {
			setDataClone(cloneDeep(orderInfo))
		}
	}, [JSON.stringify(orderInfo)]);

	function cancelHelper(): void {
		props.onCancel();
	}

	async function confirmCancelOrder(): Promise<void> {
		props.dispatch(incrementLoading());

		try {
			const res = await new MarketApi(getConfig(token)).marketCancelOrderPost({
				cancelOrderRequest: {
					order: orderInfo.order,
				},
			});

			props.dispatch(decrementLoading());
			props.dispatch(updateSidebarStandings());
			props.onDoneDeleting();

		} catch (e) {
			props.dispatch(decrementLoading());
			props.dispatch(addError(await e.json()))
		}

	}

	return (
		<Modal
			isOpen={orderInfo && Object.keys(orderInfo).length > 0}
			fade={true}
			centered={true}
			contentClassName="px-3"
			className="modal-max-600"
		>

			{dataClone && (
				<React.Fragment>
					<ModalHeader toggle={cancelHelper}>
						{`Are you sure you want to cancel this ${parseOrderTypeToString(dataClone.order.orderType)}?`}
					</ModalHeader>

					<ModalBody>
						<Row>
							<Col sm={6}>
								<h6 className="mb-1">
									Security
								</h6>
							</Col>
							<Col sm={6}>
								<p>
									{dataClone.order.security.name}
								</p>
							</Col>

							<Col sm={6}>
								<h6 className="mb-1">
									Amount Left
								</h6>
							</Col>
							<Col sm={6}>
								<p>
									{numberWithCommas(dataClone.remainingAmount)}
								</p>
							</Col>

							<Col sm={6}>
								<h6 className="mb-1">
									Price
								</h6>
							</Col>
							<Col sm={6}>
								<p>
									{formatCurrency(dataClone.order.pricePer)}
								</p>
							</Col>

							<Col sm={6}>
								<h6 className="mb-1">
									Price, June 30 2019
								</h6>
							</Col>
							<Col sm={6}>
								<p>
									{formatCurrency(dataClone.order.security.expectedValueAtExpiration)}
								</p>
							</Col>

							<Col sm={6}>
								<h6 className="mb-1">
									Above/Below Market Value
								</h6>
							</Col>
							<Col sm={6}>
								<p>
									{dataClone.order.expectedTrue ? "Above" : "Below"}
								</p>
							</Col>
						</Row>
					</ModalBody>
				</React.Fragment>
			)}

			<ModalFooter>
				<Button color="link" className="text-materialBlue" onClick={cancelHelper}>
					Cancel
				</Button>

				<Button color="materialBlue" className="ml-3" onClick={confirmCancelOrder}>
					Confirm
				</Button>
			</ModalFooter>
		</Modal>
	);
};

export default connect((store: IStore, props: ICancelCurrentOrderModalProps) => {
	return {
		token: store.metaStore.token,
		...props,
	}
})(CancelCurrentOrderModal);

