import React, {useEffect, useState} from "react";
import {connect} from "react-redux";
import {IStore} from "../redux/defaultStore";
import {Button, ButtonGroup, Card, CardBody, Col, Container, Input, Label, Row} from "reactstrap";
import StatisticsCardA from "../Components/StatisticsCardA";
import StatisticsCardB from "../Components/StatisticsCardB";
import {
	GetMarketStatisticsResponse,
	IndividualSecurityStatisticsResponse,
	MarketApi,
	MarketIdentifier,
	Security, SecurityStatistics,
	TimeFrame
} from "client";
import SelectOptions, {ISelectOptions} from "../Components/SelectOptions";
import {addError, decrementLoading, incrementLoading} from "../redux/meta/MetaActions";
import {getConfig} from "../services/clientApis";
import {expectedTrueToAboveBelow, formatCurrency, numberWithCommas} from "../utils/formatters";
import StatisticsGraph from "../Components/StatisticsGraph";

interface IStatisticsPageProps {
	token?: string;
	dispatch?: any;
}

const StatisticsPage: React.FC<IStatisticsPageProps> = (props: IStatisticsPageProps) => {

	const {token} = props;
	const [selectedTimeFrame, setSelectedTimeFrame] = useState<TimeFrame>(TimeFrame.WEEK);
	const [selectedExpected, setSelectedExpected] = useState<keyof IndividualSecurityStatisticsResponse>("combined");
	const [statistics, setStatistics] = useState<GetMarketStatisticsResponse>();
	const [selectedSecurity, setSelectedSecurity] = useState("");
	const [securitySelectOptions, setSecuritySelectOptions] = useState<Array<ISelectOptions>>([]);

	useEffect(() => {
		readStatistics().then().catch();
	}, [selectedTimeFrame]);

	/**
	 * listen for first time the drop down options get set,
	 * in which case set the selectedSecurity to index 0 of the array
	 *
	 */
	useEffect(() => {
		if (securitySelectOptions.length > 0) {
			setSelectedSecurity(securitySelectOptions[0].value);
		}
	}, [securitySelectOptions]);

	function changeSecurity(e): void {
		setSelectedSecurity(e.target.value);
	}

	function changeTimeFrame(tf: TimeFrame): () => void {
		return () => {
			setSelectedTimeFrame(tf);
		}
	}

	function changeSelectedExpected(se: keyof IndividualSecurityStatisticsResponse): () => void {
		return () => {
			setSelectedExpected(se);
		}
	}

	async function readStatistics(): Promise<void> {
		props.dispatch(incrementLoading());

		try {
			const res = await new MarketApi(getConfig(token)).marketGetMarketStatisticsGet({
				marketIdentifier: MarketIdentifier.RealEstateSecuritiesPlayMoneyVancouver,
				timeFrame: selectedTimeFrame,
			});

			setStatistics(res);

			// if we haven't set the drop down select options yet, do so now
			if (securitySelectOptions.length < 1) {
				setSecuritySelectOptions(Object.entries(res.individualSecurityStatistics).map((securityStatistic: [string, IndividualSecurityStatisticsResponse]): ISelectOptions => {
					return {
						value: securityStatistic[0],
						display: securityStatistic[1].combined.security.name,
					}
				}));
			}

		} catch (e) {
			props.dispatch(addError(await e.json()));
		}

		props.dispatch(decrementLoading());
	}

	// "selected security shorthand", so that we don't have to write the long path many times in the jsx.
	let sss: SecurityStatistics;
	if (statistics && selectedSecurity && selectedExpected) {
		sss = statistics.individualSecurityStatistics[selectedSecurity][selectedExpected];
	}

	return (
		<React.Fragment>
			{statistics && (
				<Container className="my-5">
					<Card>
						<CardBody>
							<h2>Internal Market Statistics</h2>
							<p>
								On this page you can view detailed information about the market trading statistics.
								A time frame can be chosen between day, week, or month to view a high level summary of
								the trading activity happening within the market.
								Additionally, on the lower half of the page you can select a specific security & an
								above/below market designation to view more detailed information about that security,
								restricted to your chosen time frame.
								<br/><br/>
								Hover your mouse over (or tap on mobile devices) to see an explanation of each
								statistic.
							</p>

							<div className="d-flex justify-content-center my-5">
								<div className="w-100" style={{maxWidth: 500}}>
									<Label>Time Frame</Label>
									<ButtonGroup size="md" className="w-100">
										<Button
											onClick={changeTimeFrame(TimeFrame.DAY)}
											color="materialBlue"
											outline={selectedTimeFrame !== TimeFrame.DAY}
										>
											Day
										</Button>
										<Button
											onClick={changeTimeFrame(TimeFrame.WEEK)}
											color="materialBlue"
											outline={selectedTimeFrame !== TimeFrame.WEEK}
										>
											Week
										</Button>
										<Button
											onClick={changeTimeFrame(TimeFrame.MONTH)}
											color="materialBlue"
											outline={selectedTimeFrame !== TimeFrame.MONTH}
										>
											Month
										</Button>
									</ButtonGroup>
								</div>
							</div>

							{selectedSecurity && (
								<React.Fragment>
									<h5 className="skinny mb-4">
										Market summary for the past
										<span className="text-lowercase">{" " + selectedTimeFrame}:</span>
									</h5>
									<Row className="justify-content-center flex-wrap mb-4">
										<Col xs={12} sm={6} md={6} lg={4} className="mb-4">
											<StatisticsCardA
												title="Most Traded"
												description={statistics.mostTradedSecurity.name}
												value={numberWithCommas(statistics.numberOfTradesMostTraded)}
												toolTip="The security that has the highest quantity traded in the selected time frame."
											/>
										</Col>
										<Col xs={12} sm={6} md={6} lg={4} className="mb-4">
											<StatisticsCardA
												title="Highest Volume"
												description={statistics.highestVolumeSecurity.name}
												value={numberWithCommas(statistics.volumeOfHighestVolume)}
												toolTip="The security that has had the largest amount of money transferred in the selected time frame."
											/>
										</Col>
										<Col xs={12} sm={6} md={6} lg={4} className="mb-4">
											<StatisticsCardA
												title="Highest Average Price"
												description={statistics.highestAveragePricedSecurity.name}
												value={formatCurrency(statistics.averagePriceOfHighestAveragePriced)}
												toolTip="The security with the highest average price in the selected time frame."
											/>
										</Col>
										<Col xs={12} sm={6} md={6} lg={4} className="mb-4">
											<StatisticsCardA
												title="Lowest Average Price"
												description={statistics.lowestAveragePricedSecurity.name}
												value={formatCurrency(statistics.averagePriceOfLowestAveragePriced)}
												toolTip="The security with the lowest average price in the selected time frame."
											/>
										</Col>
									</Row>
								</React.Fragment>
							)}

							<hr/>

							<div>
								<div className="d-flex justify-content-center my-5">
									<div className="w-100" style={{maxWidth: 500}}>
										<div className="w-100 mb-4">
											<Label for="security">Select Security</Label>
											<Input type="select" id="security" name="security"
											       placeholder="Select Security"
											       onChange={changeSecurity}
											       value={selectedSecurity}>
												<option value="" disabled selected>Select Security</option>
												<hr/>
												<SelectOptions fullOptions={securitySelectOptions}/>
											</Input>
										</div>

										<Label>Above/Below Market Value</Label>
										<ButtonGroup size="md" className="w-100">
											<Button
												onClick={changeSelectedExpected("expectedTrue")}
												color="materialBlue"
												outline={selectedExpected !== "expectedTrue"}
											>
												Above
											</Button>
											<Button
												onClick={changeSelectedExpected("combined")}
												color="materialBlue"
												outline={selectedExpected !== "combined"}
											>
												Both
											</Button>
											<Button
												onClick={changeSelectedExpected("expectedFalse")}
												color="materialBlue"
												outline={selectedExpected !== "expectedFalse"}
											>
												Below
											</Button>
										</ButtonGroup>
									</div>
								</div>

								{selectedSecurity && (
									<div>
										<h5 className="skinny mb-4">
											{selectedExpected === "expectedTrue" ? "Above market value " : (selectedExpected === "expectedFalse" ? "Below market value " : "Above & below market value ")}
											'{sss.security.name + "' "}security stats for the past
											<span className="text-lowercase">{" " + selectedTimeFrame}:</span>
										</h5>

										<div className="mb-4 d-flex justify-content-center">
											<StatisticsGraph history={sss.dataPoints} timeFrame={selectedTimeFrame}/>
										</div>

										<Row className="justify-content-center flex-wrap">
											<Col xs={12} sm={6} md={4} className="mb-4">
												<StatisticsCardB
													title="Average Price"
													value={formatCurrency(sss.averagePrice)}
													toolTip="The average traded price in the time frame"
												/>
											</Col>
											<Col xs={12} sm={6} md={4} className="mb-4">
												<StatisticsCardB
													title="Price Change"
													value={formatCurrency(sss.priceChange)}
													toolTip="The difference between the average price in the previous time frame and the current time frame."
												/>
											</Col>
											<Col xs={12} sm={6} md={4} className="mb-4">
												<StatisticsCardB
													title="Quantity Traded"
													value={numberWithCommas(sss.quantityTraded)}
													toolTip="The total quantity traded in the time frame."
												/>
											</Col>
											<Col xs={12} sm={6} md={4} className="mb-4">
												<StatisticsCardB
													title="Volume Traded"
													value={numberWithCommas(sss.volumeTraded)}
													toolTip="The total amount of money transferred in the time frame."
												/>
											</Col>
											<Col xs={12} sm={6} md={4} className="mb-4">
												<StatisticsCardB
													title="Price Ranking"
													value={numberWithCommas(sss.priceRanking)}
													toolTip="The ranking for the security in terms of average price within the time frame."
												/>
											</Col>
											<Col xs={12} sm={6} md={4} className="mb-4">
												<StatisticsCardB
													title="Volume Ranking"
													value={numberWithCommas(sss.volumeRanking)}
													toolTip="The ranking for the security in terms of quantity within the time frame."
												/>
											</Col>
											<Col xs={12} sm={6} md={4} className="mb-4">
												<StatisticsCardB
													title="Lowest Price Traded"
													value={formatCurrency(sss.lowestPriceTraded)}
													toolTip="The lowest price the security was traded for within the time frame."
												/>
											</Col>
											<Col xs={12} sm={6} md={4} className="mb-4">
												<StatisticsCardB
													title="Highest Price Traded"
													value={formatCurrency(sss.highestPriceTraded)}
													toolTip="The highest price the security was traded for within the time frame."
												/>
											</Col>
											<Col xs={12} sm={6} md={4} className="mb-4">
												<StatisticsCardB
													title="Number of Trades"
													value={numberWithCommas(sss.numberOfTrades)}
													toolTip="The number of transactions the security was traded for within the time frame."
												/>
											</Col>
										</Row>
									</div>
								)}
							</div>
						</CardBody>
					</Card>
				</Container>
			)}
		</React.Fragment>
	)
};

function createSecuritySelectOptions(securities: Array<any>): Array<ISelectOptions> {
	return securities.map((security: any, i: number) => {
		return {value: i, display: JSON.stringify(security)};
	});
}

export default connect((store: IStore, props: IStatisticsPageProps) => {
	return {
		token: store.metaStore.token,
		...props,
	}
})(StatisticsPage);
