import React from "react";
import {Card, CardBody, Container} from "reactstrap";

interface IInstructionsProps {

}

const Instructions: React.FC<IInstructionsProps> = (props: IInstructionsProps) => {

	return (
		<Container className="my-5">
			<Card>
				<CardBody>
					<div className="videoWrapper">
						<iframe width="560" height="349" src="http://www.youtube.com/embed/n_dZNLr2cME?rel=0&hd=1"
						        frameBorder="0" allowFullScreen/>
					</div>
				</CardBody>
			</Card>
		</Container>
	);
};

export default Instructions;
