import {MetaType} from "./MetaReducer";
import {IAction} from "../index";
import {APIError} from "client";
import {getToastMessage, ToastType} from "../../Components/ToastManager";

export function incrementLoading(): IAction<MetaType.LOADING, number> {
	return {
		type: MetaType.LOADING,
		payload: 1,
	}
}

export function decrementLoading(): IAction<MetaType.LOADING, number> {
	return {
		type: MetaType.LOADING,
		payload: -1,
	}
}

export function login(token: string): IAction<MetaType.LOGIN, string> {
	return {
		type: MetaType.LOGIN,
		payload: token,
	}
}

export function logout(): IAction<MetaType.LOGOUT, any> {
	return {
		type: MetaType.LOGOUT,
	}
}

export function addError(err: APIError): IAction<MetaType.ADD_ERROR, APIError> {
	return {
		type: MetaType.ADD_ERROR,
		payload: err,
	}
}

export function removeErr(index: number = 0): IAction<MetaType.REMOVE_ERR, number> {
	return {
		type: MetaType.REMOVE_ERR,
		payload: index,
	}
}

export function toggleSidebar(payload: boolean): IAction<MetaType.TOGGLE_SIDEBAR, boolean> {
	return {
		type: MetaType.TOGGLE_SIDEBAR,
		payload,
	}
}

export function addToast(type: ToastType, value: number): IAction<MetaType.ADD_TOAST, { title: string, description: string }> {
	return {
		type: MetaType.ADD_TOAST,
		payload: getToastMessage(type, value),
	}
}

export function removeToast(): IAction<MetaType.REMOVE_TOAST, any> {
	return {
		type: MetaType.REMOVE_TOAST,
	}
}
