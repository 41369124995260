import React, {ReactNode, useEffect, useState} from "react";
import Sidebar from "react-sidebar";
import {connect} from "react-redux";
import {IStore} from "../../redux/defaultStore";
import {toggleSidebar} from "../../redux/meta/MetaActions";
import SidebarContent from "./SidebarContent";
import {FiX} from "react-icons/fi";
import {useLocation} from "react-router-dom";
import Header from "../Header";
import {updateSidebarStandings} from "../../redux/financialStanding/FinancialStandingActions";

/* this width value is also referenced in sidebar.scss */
const mql = window.matchMedia(`(min-width: 992px)`);

interface ISidebarHelperProps {
	token?: string;
	dispatch?: any;
	sidebarOpen?: boolean;
	children: ReactNode;
}

const SidebarHelper: React.FC<ISidebarHelperProps> = (props: ISidebarHelperProps) => {

	let location = useLocation();
	const {token} = props;

	useEffect(() => {
		if (token) {
			props.dispatch(updateSidebarStandings());
		}
	}, [JSON.stringify(location.pathname), token]);

	const [sidebarConfig, setSidebarConfig] = useState<{ sidebarDocked: boolean, sidebarOpen: boolean }>({
		sidebarDocked: mql.matches,
		sidebarOpen: props.sidebarOpen === true,
	});

	/**
	 * Listen for redux change of the open prop, and call the function to toggle
	 *
	 */
	useEffect(() => {
		onSetSidebarOpen(props.sidebarOpen === true);
	}, [props.sidebarOpen]);

	/**
	 * Set up event listener on mount / remove it on unmount
	 *
	 */
	useEffect(() => {
		mql.addListener(mediaQueryChanged);
		return () => {
			mql.removeListener(mediaQueryChanged);
		}
	}, []);

	/**
	 * toggles the state variable for the sidebar being visible
	 *
	 * @param open
	 */
	function onSetSidebarOpen(open: boolean) {
		setSidebarConfig({...sidebarConfig, sidebarOpen: open});
	}

	/**
	 * Handle response to screen size changing
	 *
	 */
	function mediaQueryChanged() {
		setSidebarConfig({sidebarDocked: mql.matches, sidebarOpen: false});
	}

	/**
	 * Dispatch to redux to toggle open status,
	 * instead of tracking within this component.
	 *
	 */
	function toggleHelper(e?): void {
		if (e) {
			e.preventDefault();
		}
		props.dispatch(toggleSidebar(!props.sidebarOpen));
	}

	return (
		<React.Fragment>
			{["/", "/sign-up", "/404"].includes(location.pathname) ? (
				<React.Fragment>
					{props.children}
				</React.Fragment>
			) : (
				<Sidebar
					sidebar={
						<div className="sidebar-container bg-navDark">
							{/*{!sidebarConfig.sidebarDocked && (*/}
							{/*	<div className="d-flex justify-content-end mb-3">*/}
							{/*		<a href="#" onClick={toggleHelper} className="text-light">*/}
							{/*			<FiX*/}
							{/*				size="1.75rem"*/}
							{/*				style={{maxHeight: 50}}*/}
							{/*			/>*/}
							{/*		</a>*/}
							{/*	</div>*/}
							{/*)}*/}
							<SidebarContent/>
						</div>
					}
					sidebarClassName="fixed-sidebar"
					open={sidebarConfig.sidebarOpen}
					docked={sidebarConfig.sidebarDocked}
					onSetOpen={toggleHelper}
					styles={{
						root: {overflow: "initial"},
						content: {overflowY: "initial"},
					}}

				>
					{
						!["/", "/sign-up", "/404"].includes(location.pathname) &&
                        <Header/>
					}
					{props.children}
				</Sidebar>
			)}
		</React.Fragment>
	);
};

export default connect((store: IStore, props: ISidebarHelperProps) => {
	return {
		token: store.metaStore.token,
		sidebarOpen: store.metaStore.sidebarOpen,
		...props,
	}
})(SidebarHelper);
