import InitialFinancialStandingStore, {IFinancialStandingStore} from "./InitialFinancialStandingStore";
import {IAction} from "../index";
import cloneDeep from "lodash.clonedeep";

export enum FinancialStandingType {
	SET_VALUES = "SET_VALUES",
}

export default function(store: IFinancialStandingStore = InitialFinancialStandingStore, action: IAction<FinancialStandingType, any>): IFinancialStandingStore {

	const newStore: IFinancialStandingStore = cloneDeep(store);

	switch(action.type) {
		case FinancialStandingType.SET_VALUES:
			newStore.availableBalance = action.payload.availableBalance;
			newStore.currentBalance = action.payload.currentBalance;
			break;
	}

	return newStore;
}
