import React, {useEffect, useState} from "react";
import {connect} from "react-redux";
import {IStore} from "../redux/defaultStore";
import {MarketApi, MarketIdentifier, Order, OrderInformation, Portfolio, PortfolioSecurity} from "client";
import cloneDeep from "lodash.clonedeep";
import {Button, Col, Input, Label, Modal, ModalBody, ModalFooter, ModalHeader, Row} from "reactstrap";
import {formatCurrency, invertQty, numberWithCommas} from "../utils/formatters";
import NumberFormat from "react-number-format";
import {addError, addToast, decrementLoading, incrementLoading} from "../redux/meta/MetaActions";
import {getConfig} from "../services/clientApis";
import {ToastType} from "./ToastManager";
import {updateSidebarStandings} from "../redux/financialStanding/FinancialStandingActions";

interface IBuySecuritiesFromSellOrderModalProps {
	token?: string;
	dispatch?: any;
	orderInfo: OrderInformation;

	onCancel(): void;

	onDone(): void;
}

interface IBuyForm {
	quantity: any
}

const defaultForm: IBuyForm = {quantity: ""};

const BuySecuritiesFromSellOrderModal: React.FC<IBuySecuritiesFromSellOrderModalProps> = (props: IBuySecuritiesFromSellOrderModalProps) => {

	const {token, orderInfo} = props;
	const [dataClone, setDataClone] = useState<OrderInformation>();
	const [form, setForm] = useState<{ quantity: any }>(defaultForm);
	const [portfolio, setPortfolio] = useState<Portfolio>();

	useEffect(() => {
		readPortfolio().then().catch();
	}, [JSON.stringify(orderInfo)]);

	useEffect(() => {
		if (orderInfo && Object.keys(orderInfo).length > 0) {
			setDataClone(cloneDeep(orderInfo));
			setForm({quantity: invertQty(orderInfo.remainingAmount)});
		}
	}, [JSON.stringify(orderInfo)]);

	function resetAndClose(): void {
		// setForm(defaultForm);
		props.onCancel();
	}

	async function readPortfolio(): Promise<void> {
		props.dispatch(incrementLoading());

		try {
			const portfolioRes = await new MarketApi(getConfig(token)).marketGetPortfolioGet({marketIdentifier: MarketIdentifier.RealEstateSecuritiesPlayMoneyVancouver});

			setPortfolio(portfolioRes);
		} catch (e) {
			props.dispatch(addError(await e.json()))
		}

		props.dispatch(decrementLoading());
	}

	async function confirmPurchase(): Promise<void> {
		props.dispatch(incrementLoading());

		try {
			const res = await new MarketApi(getConfig(token)).marketAcceptOrderPost({
				acceptOrderRequest: {
					order: orderInfo.order,
					quantity: getQty(form.quantity) as number,
				},
			});

			setForm(defaultForm);
			props.dispatch(addToast(ToastType.ACCEPTED_SELL, getQty(form.quantity) as number));
			props.dispatch(updateSidebarStandings());
			props.onDone();

		} catch (e) {
			props.dispatch(addError(await e.json()));
		}

		props.dispatch(decrementLoading());
	}

	/**
	 * onChange for quantity input,
	 * limit maximum to 100 million
	 *
	 * @param e
	 */
	function onQuantityChange(e): void {
		let v = e.floatValue;

		if (orderInfo) {
			if (getQty(v) > orderInfo.remainingAmount) {
				v = invertQty(orderInfo.remainingAmount);
			}
		}

		setForm({quantity: v});
	}

	/**
	 * calculate the total amount of the selected security that is already owned
	 * to be displayed in the bottom part of the form.
	 *
	 * Accounts for expectedTrue & counts both owned & invested of that security type
	 *
	 */
	let amountOfSelectedSecurityOwned: number = 0;
	if (portfolio && dataClone && dataClone.order.security) {
		const ownedSelectedSecurity: PortfolioSecurity = portfolio.securities[dataClone.order.security._id];
		amountOfSelectedSecurityOwned = dataClone.order.expectedTrue ? (ownedSelectedSecurity.trueOwned) : (ownedSelectedSecurity.falseOwned);
	}

	return (
		<Modal
			isOpen={orderInfo && Object.keys(orderInfo).length > 0}
			fade={true}
			centered={true}
			contentClassName="px-3"
			className="modal-max-600"
		>
			<ModalHeader toggle={resetAndClose}>
				Buy Securities
			</ModalHeader>

			{dataClone && (
				<ModalBody>
					<h5 className="text-muted text-center skinny mb-3">
						{`${numberWithCommas(dataClone.order.initialQuantity)} x ${dataClone.order.security.name}`}
					</h5>

					<h5 className="text-center skinny mb-3">
						{getExpectedTrueString(dataClone.order.expectedTrue)}
					</h5>

					<h2 className="text-center mb-3">
						{getSaleTotal(dataClone.order, form, true)}
					</h2>

					<div className="mb-3">
						<Label for="quantity">Enter Quantity</Label>
						<Row className="mb-3">
							<Col xs={12} sm={6} className="mb-2 mb-sm-0">
								<NumberFormat
									allowLeadingZeros={false}
									placeholder="Quantity"
									value={form ? form.quantity : ""}
									customInput={Input}
									thousandSeparator={true}
									decimalScale={0}
									onValueChange={onQuantityChange}
									allowNegative={false}
								/>
							</Col>
							<Col xs={12} sm={6}
							     className="d-flex align-items-center justify-content-center justify-content-sm-start">
								<p className="mb-0">
									<span className="text-muted">
										{`(x 1,000) @ ${formatCurrency(dataClone.order.pricePer)} / security`}
									</span>
								</p>
							</Col>
						</Row>
					</div>

					<div>
						<hr/>
						<Row style={{fontSize: "11pt"}}>
							<Col xs={12} sm={6} className="mb-4 mb-sm-0">
								<div className="d-flex justify-content-between">
									<span className="mr-2">
										Balance before transaction
									</span>
									<span>
										{formatCurrency(portfolio.availableMoney)}
									</span>
								</div>

								<div className="d-flex justify-content-between text-muted">
									<span className="mr-2">
										This transaction
									</span>
									<span className="border-bottom border-dark">
										-{getSaleTotal(dataClone.order, form, true)}
									</span>
								</div>

								<div className="d-flex justify-content-between mt-2">
									<span className="mr-2">
										Balance after transaction
									</span>
									<span
										className={((portfolio.availableMoney - (getSaleTotal(dataClone.order, form) as number)) < 0) ? "text-danger" : ""}>
										{formatCurrency(portfolio.availableMoney - (getSaleTotal(dataClone.order, form) as number))}
									</span>
								</div>
							</Col>

							<Col xs={12} sm={6}>
								<div className="d-flex justify-content-between">
									<span className="mr-2">
										{`Your quantity of ${getExpectedTrueStringLower(dataClone.order.expectedTrue)} ${dataClone.order.security.name} securities`}
									</span>
									<span>
										{numberWithCommas(amountOfSelectedSecurityOwned)}
									</span>
								</div>

								<div className="d-flex justify-content-between text-muted">
									<span className="mr-2">
										This transaction
									</span>
									<span className="border-bottom border-dark">
										+{getQty(form.quantity, true)}
									</span>
								</div>

								<div className="d-flex justify-content-between mt-2">
									<span className="mr-2">
										{`New balance of ${getExpectedTrueStringLower(dataClone.order.expectedTrue)} ${dataClone.order.security.name} securities`}
									</span>
									<span>
										{numberWithCommas(amountOfSelectedSecurityOwned + (getQty(form.quantity) as number))}
									</span>
								</div>
							</Col>
						</Row>
					</div>
				</ModalBody>
			)}

			<ModalFooter>
				<Button color="link" className="text-materialBlue" onClick={resetAndClose}>
					Cancel
				</Button>

				<Button color="materialBlue" className="ml-3" onClick={confirmPurchase}>
					Confirm Purchase
				</Button>
			</ModalFooter>
		</Modal>
	);
};

/**
 * get quantity of the current offer being filled out (quantity x 1000)
 *
 * @param x
 * @param asString
 */
function getQty(x: number, asString: boolean = false): number | string {
	let q: number = Math.round(x * parseFloat(process.env.REACT_APP_SECURITY_MULTIPLIER));

	if (isNaN(q)) {
		q = 0;
	}

	return asString ? numberWithCommas(q) : q;
}

/**
 * get the total price of the offer being filled out (getQty(y) x price)
 *
 * @param order
 * @param form
 * @param asString
 */
function getSaleTotal(order: Order, form: { quantity: number } = {quantity: 0}, asString: boolean = false): number | string {
	let t: number = order.pricePer * (getQty(form.quantity) as number);

	if (isNaN(t)) {
		t = 0;
	}

	return asString ? formatCurrency(t) : t;
}

/**
 * let the form handle the values of the expectedTrue as just true/false or 0/1, etc.
 * helper function to determine string
 *
 * @param expected
 */
function getExpectedTrueString(expected?: boolean): string {
	return expected ? "Above Market Value" : "Below Market Value";
}

function getExpectedTrueStringLower(expected?: boolean): string {
	return expected ? "above market value" : "below market value";
}

export default connect((store: IStore, props: IBuySecuritiesFromSellOrderModalProps) => {
	return {
		token: store.metaStore.token,
		...props,
	}
})(BuySecuritiesFromSellOrderModal);
