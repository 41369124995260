import React, {useEffect, useState} from "react";
import {connect} from "react-redux";
import {IStore} from "../redux/defaultStore";
import {addError, decrementLoading, incrementLoading} from "../redux/meta/MetaActions";
import InternalMarketHistoryTable, {IInternalMarketHistoryTableRow} from "../Components/InternalMarketHistoryTable";
import {Card, CardBody} from "reactstrap";
import {MarketApi, MarketIdentifier} from "client";
import {getConfig} from "../services/clientApis";
import cloneDeep from "lodash.clonedeep";

interface IMarketTradingHistoryPageProps {
	token?: string;
	dispatch?: any;
}

const MarketTradingHistoryPage: React.FC<IMarketTradingHistoryPageProps> = (props: IMarketTradingHistoryPageProps) => {

	const {token} = props;
	const [internalMarketHistory, setInternalMarketHistory] = useState<Array<IInternalMarketHistoryTableRow>>(undefined);

	useEffect(() => {
		readInternalMarketHistory().then().catch();
	}, []);

	async function readInternalMarketHistory(): Promise<void> {
		props.dispatch(incrementLoading());

		try {
			const res = await new MarketApi(getConfig(token)).marketGetTransactionsForMarketGet({
				marketIdentifier: MarketIdentifier.RealEstateSecuritiesPlayMoneyVancouver,
			});

			const data = cloneDeep(res.responseTransactions);
			const dataForTable: Array<IInternalMarketHistoryTableRow> = [];

			for (const d of data) {
				dataForTable.push({
					raw: d,
					date: d.date,
					listingType: d.orderType,
					propertyType: d.security.name,
					pricePerSecurity: d.order.pricePer,
					expectedTrue: d.expectedTrue,
					quantity: d.quantity,
					moneyTraded: d.moneyTraded,
					expirationDate: d.expirationDate,
					priceAtExpiration: d.expectedValueAtExpiration as number,
				});
			}

			setInternalMarketHistory(dataForTable);

		} catch (e) {
			props.dispatch(addError(await e.json()))
		}

		props.dispatch(decrementLoading());
	}

	return (
		<div>
			<div className="my-5 px-1 px-lg-2">
				<Card>
					<CardBody>
						<h2 className="mb-4">
							Internal Market History
						</h2>
					</CardBody>

					<InternalMarketHistoryTable data={internalMarketHistory}/>
				</Card>
			</div>
		</div>
	)
};

export default connect((store: IStore, props: IMarketTradingHistoryPageProps) => {
	return {
		token: store.metaStore.token,
		...props,
	}
})(MarketTradingHistoryPage);
