import React, {ReactNode, useEffect, useRef, useState} from "react";
import {Col, Container, Row} from "reactstrap";
import {connect} from "react-redux";
import {IStore} from "../redux/defaultStore";
import {Article, ArticleMarket, RealestateSecuritiesApi} from "client";
import {addError, decrementLoading, incrementLoading} from "../redux/meta/MetaActions";
import ArticleMarketThumbnailDisplay from "../Components/ArticleMarketThumbnailDisplay";
import isEqual from "lodash.isequal";
import NewsFeedArticleCard from "../Components/NewsFeedArticleCard";
import {getConfig} from "../services/clientApis";

interface IRealEstateMarketProps {
	token?: string;
	dispatch?: any;
}

/**
 * Scroll to ref
 *
 * @param ref
 */
function scrollToRef(ref: any): void {
	const top: any = ref.current.offsetTop;
	window.scrollTo({behavior: "smooth", top: top - 100});
}

const RealEstateMarket: React.FC<IRealEstateMarketProps> = (props: IRealEstateMarketProps) => {

	const {token} = props;
	const [markets, setMarkets] = useState<Array<ArticleMarket>>([]);
	const [selectedMarket, setSelectedMarket] = useState<ArticleMarket>();
	const [articles, setArticles] = useState<Array<Article>>([]);
	const articlesRef = useRef(null);

	useEffect(() => {
		readMarkets().then().catch();
	}, []);

	/**
	 * Call api and get the list of article markets
	 *
	 */
	async function readMarkets(): Promise<void> {
		props.dispatch(incrementLoading());

		try {
			const res = await new RealestateSecuritiesApi(getConfig(token)).realestateSecuritiesGetArticleMarketListGet({});
			setMarkets(res);
		} catch (e) {
			props.dispatch(addError(await e.json()));
		}

		props.dispatch(decrementLoading());
	}

	/**
	 * Handle selecting a market. If trying to select the currently selected market,
	 * don't do api call and just scroll to the articles.
	 *
	 * @param market
	 */
	async function selectMarket(market: ArticleMarket): Promise<void> {
		if (!isEqual(selectedMarket, market)) {
			setSelectedMarket(market);
			await readArticlesForMarket(market);
		} else {
			scrollToRef(articlesRef);
		}
	}

	/**
	 * Call api to get list of articles for the selected market
	 *
	 * @param market
	 */
	async function readArticlesForMarket(market: ArticleMarket): Promise<void> {
		props.dispatch(incrementLoading());

		try {
			const res = await new RealestateSecuritiesApi(getConfig(token)).realestateSecuritiesGetArticlesForArticleMarketPost({articleMarket: market});
			setArticles(res);
			scrollToRef(articlesRef);
		} catch (e) {
			props.dispatch(addError(await e.json()));
		}

		props.dispatch(decrementLoading());
	}

	/**
	 * Iterate over the Article Markets and create a display for each one.
	 * They are selectable and when clicked will call selectMarket();
	 *
	 * @param ams
	 */
	function mapArticleMarkets(ams: Array<ArticleMarket> = []): ReactNode {
		return ams.map((am: ArticleMarket, i: number) => {

			function selectHelper(): void {
				selectMarket(am).then().catch();
			}

			return (
				<Col xs={12} sm={6} md={6} lg={4} key={`article-market-${i}`} className="mb-4">
					<ArticleMarketThumbnailDisplay
						market={am}
						active={isEqual(am, selectedMarket)}
						onSelect={selectHelper}
					/>
				</Col>
			);
		})
	}

	/**
	 * Iterate the articles and return a component for each
	 *
	 * @param as
	 */
	function mapArticles(as: Array<Article> = []): ReactNode {
		if (articles.length < 1) {
			return (
				<div className="w-100 my-3">
					<p className="text-secondary font-italic text-center">
						{`There are currently no articles about ${selectedMarket?.title}.`}
					</p>
				</div>
			);
		}

		return as.map((a: Article, i: number) => {
			return (
				<Col xs={12} sm={12} md={6} lg={6} key={`news-feed-article-${i}`} className="mb-4">
					<NewsFeedArticleCard article={a} hero={false}/>
				</Col>
			)
		});
	}

	return (
		<Container className="my-5">
			<div className="mb-4">
				<h2>Real Estate Markets</h2>
				<p>
					Click or tap on a market below to view articles about it. Once you've selected a market, you can click or tap on one of the articles to continue reading.
				</p>
			</div>

			<Row className="flex-wrap mb-4">
				{mapArticleMarkets(markets)}
			</Row>

			<div ref={articlesRef}>
				{selectedMarket && (
					<React.Fragment>

						<h3 className="mb-3">
							{`Articles regarding ${selectedMarket?.title}`}
						</h3>
						<Row className="flex-wrap mb-4">
							{mapArticles(articles)}
						</Row>
					</React.Fragment>
				)}
			</div>
		</Container>
	);
};

export default connect((store: IStore, props: IRealEstateMarketProps) => {
	return {
		token: store.metaStore.token,
		...props,
	}
})(RealEstateMarket);
