import React from "react";
import BootstrapTable from 'react-bootstrap-table-next';
import {Order, OrderInformation} from "client";
import {
	expectedTrueToAboveBelow,
	formatCurrency,
	momentStringFromMilliseconds,
	numberWithCommas
} from "../utils/formatters";
import {
	createTradingTableActionButton, IColumn,
	whiteSpaceCellFormatter
} from "../utils/tableCellHTMLFormatters";

export interface IBuyOrderTableRow {
	raw: OrderInformation,
	name: string;
	expirationDate: Date;
	priceAtExpiration: number;
	priceAtInitialization: number;
	quantity: number;
	pricePer: number;
	expectedTrue: boolean;
	action(orderInfo: OrderInformation): void;
}

interface IBuyOrdersTableProps {
	data: Array<IBuyOrderTableRow>
}

const BuyOrdersTable: React.FC<IBuyOrdersTableProps> = (props: IBuyOrdersTableProps) => {

	const {data} = props;

	return (
		<BootstrapTable
			bootstrap4={true}
			hover={true}
			keyField="id"
			data={data ? data : []}
			columns={columns}
			bordered={false}
			condensed={true}
			noDataIndication={data === undefined ? "Loading..." : "There are currently no Buy Orders."}
		/>
	);
};

const columns: Array<IColumn> = [
	{dataField: "name", text: "Security", formatter: whiteSpaceCellFormatter, sort: true},
	{dataField: "expirationDate", text: "Expiration Date", formatter: momentStringFromMilliseconds, sort: true, headerAlign: "right", classes: "text-right"},
	{dataField: "priceAtExpiration", text: "Price At Expiration", formatter: formatCurrency, sort: true, headerAlign: "right", classes: "text-right"},
	{dataField: "priceAtInitialization", text: "Price, June 30 2019", formatter: formatCurrency, sort: true, headerAlign: "right", classes: "text-right"},
	{dataField: "quantity", text: "Quantity", formatter: numberWithCommas, sort: true, headerAlign: "right", classes: "text-right"},
	{dataField: "pricePer", text: "Price Per Security", formatter: formatCurrency, sort: true, headerAlign: "right", classes: "text-right"},
	{dataField: "expectedTrue", text: "Above/Below Market Value", formatter: expectedTrueToAboveBelow, sort: true, headerAlign: "right", classes: "text-right"},
	{dataField: "action", text: "", formatter: createTradingTableActionButton("Sell Securities")},
];

export default BuyOrdersTable;
