import React, {useEffect, useState} from "react";
import {connect} from "react-redux";
import {IStore} from "../redux/defaultStore";
import {addError, decrementLoading, incrementLoading} from "../redux/meta/MetaActions";
import CurrentOrdersTable, {ICurrentOrdersTableRow} from "../Components/CurrentOrdersTable";
import HistoryOfOrdersTable, {IHistoryOfOrdersTableRow} from "../Components/HistoryOfOrdersTable";
import CancelCurrentOrderModal from "../Components/CancelCurrentOrderModal";
import cloneDeep from "lodash.clonedeep";
import {
	MarketApi,
	MarketGetUsersOrdersGetOrderActiveSelectionEnum,
	MarketIdentifier,
	Order,
	OrderInformation
} from "client";
import {Card, CardBody} from "reactstrap";
import {getConfig} from "../services/clientApis";
import moment, {Moment} from "moment";

interface IMyOrdersProps {
	token?: string;
	dispatch: any;
}

const MyOrders: React.FC<IMyOrdersProps> = (props: IMyOrdersProps) => {

	const {token} = props;
	const [currentOrders, setCurrentOrders] = useState<Array<ICurrentOrdersTableRow>>(undefined);
	const [historyOfOrders, setHistoryOfOrders] = useState<Array<IHistoryOfOrdersTableRow>>(undefined);
	const [selectedOrderForDeleting, setSelectedOrderForDeleting] = useState<OrderInformation>();

	useEffect(() => {
		readUserOrders().then().catch();
	}, []);

	/**
	 * call same api twice with different parameters to get current orders & order history
	 *
	 */
	async function readUserOrders(): Promise<void> {
		props.dispatch(incrementLoading());

		try {
			const res = await new MarketApi(getConfig(token)).marketGetUsersOrdersGet({
				orderActiveSelection: MarketGetUsersOrdersGetOrderActiveSelectionEnum.True,
				marketIdentifier: MarketIdentifier.RealEstateSecuritiesPlayMoneyVancouver,
			});

			const data = cloneDeep(res);
			const dataForTable: Array<ICurrentOrdersTableRow> = [];

			for (const d of data) {
				dataForTable.push({
					raw: d,
					action: selectOrderToPromptDelete,
					listingType: d.order.orderType,
					propertyType: d.order.security.name,
					expirationDate: d.order.security.expirationDate,
					amountLeft: d.remainingAmount,
					pricePerSecurity: d.order.pricePer,
					priceAtExpiration: d.order.security.expectedValueAtExpiration,
					expectedTrue: d.order.expectedTrue,
				});
			}

			setCurrentOrders(dataForTable);

		} catch (e) {
			props.dispatch(addError(await e.json()))
		}

		try {
			const historyRes = await new MarketApi(getConfig(token)).marketGetUsersOrdersGet({
				orderActiveSelection: MarketGetUsersOrdersGetOrderActiveSelectionEnum.Both,
				marketIdentifier: MarketIdentifier.RealEstateSecuritiesPlayMoneyVancouver,
			});

			const historyData: Array<OrderInformation> = cloneDeep(historyRes);
			const historyDataForTable: Array<IHistoryOfOrdersTableRow> = [];

			for (const d of historyData) {
				historyDataForTable.push({
					raw: d,
					listingType: d.order.orderType,
					propertyType: d.order.security.name,
					date: moment(d.transactions[0].date).format("MMM Do YYYY"),
					amount: d.order.initialQuantity,
					pricePerSecurity: d.order.pricePer,
					moneyTransferred: d.moneyTransferredSoFar,
					active: d.trueActive,
					expectedTrue: d.order.expectedTrue,
				});
			}

			setHistoryOfOrders(historyDataForTable);

		} catch (e) {
			props.dispatch(addError(await e.json()))
		}

		props.dispatch(decrementLoading());

	}

	/**
	 * handle selecting an order from the "current orders"
	 * table to prompt the confirm delete modal,
	 * also handle onCancel (set to null)
	 *
	 * @param orderInfo
	 */
	function selectOrderToPromptDelete(orderInfo?: OrderInformation): void {
		if (orderInfo) {
			setSelectedOrderForDeleting(orderInfo);
		} else {
			setSelectedOrderForDeleting(null);
		}
	}

	function onDoneDeleting(): void {
		setSelectedOrderForDeleting(null);
		readUserOrders().then().catch();
	}

	return (
		<React.Fragment>

			<CancelCurrentOrderModal
				orderInfo={selectedOrderForDeleting}
				onCancel={selectOrderToPromptDelete}
				onDoneDeleting={onDoneDeleting}
			/>

			<div className="my-5 px-1 px-lg-2">
				<Card>
					<CardBody>
						<h2 className="mb-4">
							Current Orders
						</h2>
					</CardBody>

					<CurrentOrdersTable data={currentOrders}/>
					<hr/>

					<CardBody>
						<h2 className="mb-4">
							History of Orders
						</h2>
					</CardBody>

					<HistoryOfOrdersTable data={historyOfOrders}/>
				</Card>
			</div>
		</React.Fragment>
	)
};

export default connect((store: IStore, props: IMyOrdersProps) => {
	return {
		token: store.metaStore.token,
		...props,
	}
})(MyOrders);
