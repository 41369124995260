import InitialMetaStore, {IMetaStore} from "./InitialMetaStore";
import {IAction} from "../index";
import cloneDeep from "lodash.clonedeep";
import {getToastMessage} from "../../Components/ToastManager";

export enum MetaType {
	LOGIN = "LOGIN",
	LOGOUT = "LOGOUT",
	LOADING = "LOADING",
	ADD_ERROR = "ADD_ERROR",
	REMOVE_ERR = "REMOVE_ERR",
	TOGGLE_SIDEBAR = "TOGGLE_SIDEBAR",
	ADD_TOAST = "ADD_TOAST",
	REMOVE_TOAST = "REMOVE_TOAST",
}

export default function(store: IMetaStore = InitialMetaStore, action: IAction<MetaType, any>): IMetaStore {

	const newStore: IMetaStore = cloneDeep(store);

	switch(action.type) {
		case MetaType.LOGIN:
			newStore.token = action.payload;
			break;
		case MetaType.LOGOUT:
			delete newStore.token;
			break;
		case MetaType.LOADING:
			if ((newStore.loadingCount + action.payload) >= 0) {
				newStore.loadingCount += action.payload;
			} else {
				newStore.loadingCount = 0;
			}
			break;
		case MetaType.ADD_ERROR:
			newStore.errors.push(action.payload);
			break;
		case MetaType.REMOVE_ERR:
			newStore.errors.splice(action.payload, 1);
			break;
		case MetaType.TOGGLE_SIDEBAR:
			newStore.sidebarOpen = action.payload;
			break;
		case MetaType.ADD_TOAST:
			newStore.toastMessage = action.payload;
			break;
		case MetaType.REMOVE_TOAST:
			delete newStore.toastMessage;
			break;

	}

	return newStore;

}
