import React, {useEffect, useState} from "react";
import {connect} from "react-redux";
import {IStore} from "../redux/defaultStore";
import {Button, Card, CardBody, Container} from "reactstrap";
import BuyOrdersTable, {IBuyOrderTableRow} from "../Components/BuyOrdersTable";
import cloneDeep from "lodash.clonedeep";
import {
	MarketApi,
	MarketUserGetOrdersForMarketGetOrderTypeEnum,
	MarketUserGetOrdersForMarketGetOrderActiveSelectEnum,
	MarketIdentifier,
	Order,
	PortfolioSecurity, OrderInformation
} from "client";
import PostBuyOfferModal from "../Components/PostBuyOfferModal";
import SellSecuritiesToBuyOrderModal from "../Components/SellSecuritiesToBuyOrderModal";
import {addError, decrementLoading, incrementLoading} from "../redux/meta/MetaActions";
import {getConfig} from "../services/clientApis";

interface IBuyOrdersPageProps {
	token?: string;
	dispatch?: any;
}

const BuyOrdersPage: React.FC<IBuyOrdersPageProps> = (props: IBuyOrdersPageProps) => {

	const {token} = props;
	const [buyOrders, setBuyOrders] = useState<Array<IBuyOrderTableRow>>();
	const [showCreateOrderModal, setShowCreateOrderModal] = useState(false);
	const [orderToSellSecuritiesTo, setOrderToSellSecuritiesTo] = useState<OrderInformation>();

	useEffect(() => {
		readBuyOrders().then().catch();
	}, []);

	/**
	 * toggle the post buy order modal
	 *
	 * **/
	function toggleShowCreateOrderModal(): void {
		setShowCreateOrderModal(!showCreateOrderModal);
	}

	/**
	 * When clicking the "Sell Securities" button on one of the table rows,
	 * this function gets called to set the selected order to a state variable,
	 * prompting the modal to open with the form
	 *
	 * @param orderInfo
	 */
	function selectOrderToSellTo(orderInfo?: OrderInformation): void {
		if (orderInfo) {
			setOrderToSellSecuritiesTo(orderInfo);
		} else {
			setOrderToSellSecuritiesTo(null);
		}
	}

	/**
	 * Called to fetch the data for the buy order table
	 *
	 */
	async function readBuyOrders(): Promise<void> {
		props.dispatch(incrementLoading());

		try {
			const res = await new MarketApi(getConfig(token)).marketUserGetOrdersForMarketGet({
				orderActiveSelect: MarketUserGetOrdersForMarketGetOrderActiveSelectEnum.True,
				marketIdentifier: MarketIdentifier.RealEstateSecuritiesPlayMoneyVancouver,
				includeOrderInformation: true,
				orderType: MarketUserGetOrdersForMarketGetOrderTypeEnum.BuyOrder,
			});

			const data = cloneDeep(res);
			const dataForTable: Array<IBuyOrderTableRow> = [];

			for (const d of data) {
				dataForTable.push({
					raw: d,
					action: selectOrderToSellTo,
					name: d.order.security.name,
					expirationDate: d.order.security.expirationDate,
					priceAtExpiration: d.order.security.expectedValueAtExpiration,
					priceAtInitialization: d.order.security.lastUpdatedValue,
					quantity: d.remainingAmount,
					pricePer: d.order.pricePer,
					expectedTrue: d.order.expectedTrue,
				})
			}

			setBuyOrders(dataForTable);

		} catch (e) {
			props.dispatch(addError(await e.json()))
		}

		props.dispatch(decrementLoading());
	}

	function onPostedOrder(): void {
		setShowCreateOrderModal(false);
		readBuyOrders().then().catch();
	}

	function onAcceptedOrder(): void {
		setOrderToSellSecuritiesTo(null);
		readBuyOrders().then().catch();
	}

	return (
		<React.Fragment>
			<PostBuyOfferModal
				isOpen={showCreateOrderModal}
				onCancel={toggleShowCreateOrderModal}
				onDone={onPostedOrder}
			/>

			<SellSecuritiesToBuyOrderModal
				orderInfo={orderToSellSecuritiesTo}
				onCancel={selectOrderToSellTo}
				onDone={onAcceptedOrder}
			/>

			<div className="my-5 px-1 px-lg-2">
				<Card>
					<CardBody>
						<div className="d-flex justify-content-center mb-4">
							<Button color="materialBlue" onClick={toggleShowCreateOrderModal}>
								Post a Buy Order
							</Button>
						</div>
					</CardBody>

					<BuyOrdersTable data={buyOrders}/>
				</Card>
			</div>
		</React.Fragment>
	);
};

export default connect((store: IStore, props: IBuyOrdersPageProps) => {
	return {
		token: store.metaStore.token,
		...props,
	}
})(BuyOrdersPage);
