import React from "react";
import {Card, Col, Row} from "reactstrap";
import {Article} from "client";

interface INewsFeedArticleCardProps {
	article: Article;
	hero?: boolean;
}

const NewsFeedArticleCard: React.FC<INewsFeedArticleCardProps> = (props: INewsFeedArticleCardProps) => {

	const {article, hero} = props;

	return (
		<a href={article.articleURL} target="_blank" className="h-100 w-100 d-flex text-dark text-decoration-none news-item">
			<Card style={{borderRadius: 10}} className="overflow-hidden">
				<Row className="no-gutters">
					<Col xs={12} sm={12} md={12} lg={hero ? 6 : 12}>

						<div style={{width: "100%", height: hero ? "100%" : 240}} className="overflow-hidden">
							<img
								style={{width: "100%", height: "100%", objectFit: "cover"}}
								src={article.thumbnail.url}
								alt={`${article.title}-thumbnail`}
								className="news-image"
							/>
						</div>
					</Col>

					<Col xs={12} sm={12} md={12} lg={hero ? 6 : 12}>

						<div className="p-3">
							<h3>
								{article.title}
							</h3>
							<p className="text-secondary font-italic">
								{"Source: " + article.source}
							</p>
							<p>
								{article.description}
							</p>
						</div>
					</Col>
				</Row>
			</Card>
		</a>
	);
};

export default NewsFeedArticleCard;
