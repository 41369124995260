import {AnyAction, applyMiddleware, combineReducers, createStore, Reducer, Store, StoreEnhancer} from "redux";
import storage from "redux-persist/lib/storage";
import {persistStore, persistReducer} from "redux-persist";
import logger from "redux-logger";
import defaultStore, {IStore} from "./defaultStore";
import MetaReducer from "./meta/MetaReducer";
import FinancialStandingReducer from "./financialStanding/FinancialStandingReducer";
import promiseMiddleware from "redux-promise";

const metaPersistConfig = {
	key: "meta",
	storage,
	whitelist: ["token"],
};

const reducers: Reducer<any, any> = combineReducers({
	metaStore: persistReducer(metaPersistConfig, MetaReducer),
	financialStandingStore: FinancialStandingReducer,
});

const middleware: StoreEnhancer = applyMiddleware(
	logger,
	promiseMiddleware,
);

export const store: Store<IStore> = createStore(reducers, defaultStore, middleware);
export const persistor = persistStore(store);

export interface IAction<T = any, P = undefined> extends AnyAction {
	type: T,
	payload?: P,
}
