import React, {useEffect, useState} from "react";
import {Transaction, TransactionAction} from "client";
import {Button, Label, Modal, ModalBody, ModalFooter, ModalHeader} from "reactstrap";
import cloneDeep from "lodash.clonedeep";
import moment from "moment";
import {expectedTrueToAboveBelow, formatCurrency, numberWithCommas} from "../utils/formatters";

interface ITransactionSummaryModalProps {
	transaction: Transaction;

	onClose(): void;
}

const TransactionSummaryModal: React.FC<ITransactionSummaryModalProps> = (props: ITransactionSummaryModalProps) => {

	const {transaction} = props;
	const [dataClone, setDataClone] = useState<Transaction>();

	useEffect(() => {
		if (transaction && Object.keys(transaction).length > 0) {
			setDataClone(cloneDeep(transaction))
		}
	}, [JSON.stringify(transaction)]);

	function onCloseHelper(): void {
		props.onClose();
	}

	return (
		<Modal
			isOpen={transaction && Object.keys(transaction).length > 0}
			fade={true}
			centered={true}
			contentClassName="px-3"
			toggle={onCloseHelper}
		>
			{dataClone && (
				<React.Fragment>
					<ModalHeader toggle={onCloseHelper}>Transaction Details</ModalHeader>

					<ModalBody>
						<div>
							<Label for="date" className="text-secondary">Transaction Date</Label>
							<p>
								{moment(dataClone.date).format("MMM Do YYYY")}
							</p>
						</div>

						{dataClone.description && (
							<div>
								<Label for="description" className="text-secondary">Description</Label>
								<p>
									{dataClone.description}
								</p>
							</div>
						)}

						<div>
							<Label for="transactionType" className="text-secondary">Transaction Type</Label>
							<p>
								{dataClone.transactionType}
							</p>
						</div>

						{dataClone.tradeTransaction && (
							<React.Fragment>
								{/*<div>*/}
								{/*	<Label for="securityTransactionOrderType" className="text-secondary">Order Type</Label>*/}
								{/*	<p>*/}
								{/*		{parseOrderTypeToString(dataClone.securityTransaction.order.orderType)}*/}
								{/*	</p>*/}
								{/*</div>*/}

								<div>
									<Label for="securityTransactionSecurity" className="text-secondary">Security</Label>
									<p>
										{dataClone.tradeTransaction.security.name}
									</p>
								</div>

								<div>
									<Label for="securityTransactionQuantity" className="text-secondary">Quantity</Label>
									<p>
										{numberWithCommas(dataClone.tradeTransaction.quantity)}
									</p>
								</div>

								<div>
									<Label for="securityTransactionPricePer" className="text-secondary">Price Per Security</Label>
									<p>
										{formatCurrency(dataClone.tradeTransaction.order.pricePer)}
									</p>
								</div>

								<div>
									<Label for="securityTransactionTotal" className="text-secondary">Total</Label>
									<p>
										{formatCurrency(dataClone.tradeTransaction.order.pricePer * dataClone.tradeTransaction.quantity)}
									</p>
								</div>
							</React.Fragment>
						)}

						{dataClone.orderTransaction && (
							<React.Fragment>
								{/*<div>*/}
								{/*	<Label for="securityTransactionOrderType" className="text-secondary">Order Type</Label>*/}
								{/*	<p>*/}
								{/*		{parseOrderTypeToString(dataClone.securityTransaction.order.orderType)}*/}
								{/*	</p>*/}
								{/*</div>*/}

								<div>
									<Label for="securityTransactionSecurity" className="text-secondary">Security</Label>
									<p>
										{dataClone.orderTransaction.security.name}
									</p>
								</div>

								<div>
									<Label for="securityTransactionQuantity" className="text-secondary">Quantity</Label>
									<p>
										{numberWithCommas(dataClone.orderTransaction.quantity)}
									</p>
								</div>

								<div>
									<Label for="securityTransactionPricePer" className="text-secondary">Price Per Security</Label>
									<p>
										{formatCurrency(dataClone.orderTransaction.order.pricePer)}
									</p>
								</div>

								<div>
									<Label for="securityTransactionTotal" className="text-secondary">Total</Label>
									<p>
										{formatCurrency(dataClone.orderTransaction.order.pricePer * dataClone.orderTransaction.quantity)}
									</p>
								</div>
							</React.Fragment>
						)}

						{dataClone.bankingTransaction && (
							<div>
								<Label for="bankingMonetaryChange" className="text-secondary">Monetary Change</Label>
								<p>
									{formatCurrency(dataClone.bankingTransaction.monetaryChange)}
								</p>
							</div>
						)}

						{dataClone.exchangeTransaction && (
							<React.Fragment>
								<div>
									<Label for="exTransactionSecurityName" className="text-secondary">Security</Label>
									<p>
										{dataClone.exchangeTransaction.security.name}
									</p>
								</div>

								<div>
									<Label for="exTransactionSecurityAbove" className="text-secondary">Above Market Value Quantity Gained</Label>
									<p>
										{numberWithCommas(dataClone.exchangeTransaction.quantity)}
									</p>
								</div>

								<div>
									<Label for="exTransactionSecurityBelow" className="text-secondary">Below Market Value Quantity Gained</Label>
									<p>
										{numberWithCommas(dataClone.exchangeTransaction.quantity)}
									</p>
								</div>

								<div>
									<Label for="exTransactionSecurityPrice" className="text-secondary">Cost to You</Label>
									<p className="text-danger">
										{formatCurrency(dataClone.exchangeTransaction.quantity)}
									</p>
								</div>
							</React.Fragment>
						)}

						{dataClone.securityGiftTransaction && (
							<React.Fragment>
								<div>
									<Label for="securityGiftSecurity" className="text-secondary">Security</Label>
									<p>
										{dataClone.securityGiftTransaction.security.name}
									</p>
								</div>

								<div>
									<Label for="securityGiftExpected" className="text-secondary">Above/Below Market Value</Label>
									<p>
										{expectedTrueToAboveBelow(dataClone.securityGiftTransaction.expectedTrue)}
									</p>
								</div>

								<div>
									<Label for="securityGiftQuantity" className="text-secondary">Quantity</Label>
									<p>
										{numberWithCommas(dataClone.securityGiftTransaction.quantity)}
									</p>
								</div>
							</React.Fragment>
						)}

						{dataClone.payoutTransaction && (
                            <React.Fragment>
	                            <div>
		                            <Label for="payoutTransactionSecurity" className="text-secondary">Security</Label>
		                            <p>
			                            {dataClone.payoutTransaction.security.name}
		                            </p>
	                            </div>

	                            <div>
		                            <Label for="payoutTransactionQuantity" className="text-secondary">Quantity</Label>
		                            <p>
			                            {numberWithCommas(dataClone.payoutTransaction.quantity)}
		                            </p>
	                            </div>

	                            <div>
		                            <Label for="payoutTransactionPricePer" className="text-secondary">Price Per Security</Label>
		                            <p>
			                            {formatCurrency(dataClone.payoutTransaction.amountPerSecurity)}
		                            </p>
	                            </div>
                            </React.Fragment>
						)}

					</ModalBody>

					<ModalFooter>
						<Button color="materialBlue" onClick={onCloseHelper}>
							Close
						</Button>
					</ModalFooter>
				</React.Fragment>
			)}
		</Modal>
	);
};

export default TransactionSummaryModal;
