import {connect} from "react-redux";
import {IStore} from "../redux/defaultStore";
import React, {useEffect} from "react";
import {updateSidebarStandings} from "../redux/financialStanding/FinancialStandingActions";

interface IPortfolioIntervalCheckerProps {
	token?: string;
	dispatch?: any;
}

const PortfolioIntervalChecker: React.FC<IPortfolioIntervalCheckerProps> = (props: IPortfolioIntervalCheckerProps) => {

	useEffect(() => {
		updatePortfolio().then().catch();
		const interval = setInterval(updatePortfolio, 30000);

		return () => clearInterval(interval);
	}, [JSON.stringify(props.token)]);

	async function updatePortfolio(): Promise<void> {
		if (!props.token) {
			return
		}

		props.dispatch(updateSidebarStandings());
	}

	return null;
};

export default connect((store: IStore, props: IPortfolioIntervalCheckerProps) => {
	return {
		token: store.metaStore.token,
	}
})(PortfolioIntervalChecker);
