import * as React from "react";
import {ReactNode} from "react";

export interface ISelectOptions {
	value: any;
	display: string;
}

interface ISelectHelperProps {
	stringsOnly?: string[];
	fullOptions?: ISelectOptions[];
}

const SelectOptions: React.FC<ISelectHelperProps> = (props: ISelectHelperProps) => {

	const {stringsOnly, fullOptions} = props;

	function makeOptions(ops: ISelectOptions[]): ReactNode {
		return ops.map((op: ISelectOptions, i: number) => {
			return (
				<option value={op.value} key={`option-${i}`}>{op.display}</option>
			)
		});
	}

	return (
		<React.Fragment>
			{stringsOnly ? (
				<React.Fragment>
					{makeOptions(stringsOnly.map((str: string) => {
						return {
							value: str,
							display: str,
						}
					}))}
				</React.Fragment>
			) : fullOptions ? (
				<React.Fragment>
					{makeOptions(fullOptions)}
				</React.Fragment>
			) : null}
		</React.Fragment>
	)
};

export default SelectOptions;
