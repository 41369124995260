import React, {useEffect, useState} from "react";
import {connect} from "react-redux";
import {IStore} from "../redux/defaultStore";
import {
	MarketApi,
	MarketGetSecuritiesForMarketGetIsActiveEnum,
	MarketIdentifier, Portfolio, PortfolioSecurity,
	Security,
	UserBuyFromExchangeBody
} from "client";
import {addError, decrementLoading, incrementLoading} from "../redux/meta/MetaActions";
import {getConfig} from "../services/clientApis";
import {Button, Col, Input, Label, Modal, ModalBody, ModalFooter, ModalHeader, Row} from "reactstrap";
import findIndex from "lodash.findindex";
import NumberFormat from "react-number-format";
import {formatCurrency, numberWithCommas} from "../utils/formatters";
import GenericSuccessModal from "./GenericSuccessModal";
import {updateSidebarStandings} from "../redux/financialStanding/FinancialStandingActions";

interface IPurchaseSecuritiesFromMarketModalProps {
	token?: string;
	dispatch?: any;
	isOpen: boolean;
	onClose(): void;
}

const defaultPurchaseSecuritiesForm: UserBuyFromExchangeBody = {
	security: null,
	quantity: "" as any,
};

const PurchaseSecuritiesFromMarketModal: React.FC<IPurchaseSecuritiesFromMarketModalProps> = (props: IPurchaseSecuritiesFromMarketModalProps) => {

	const {token, isOpen} = props;
	const [securities, setSecurities] = useState<Array<Security>>([]);
	const [form, setForm] = useState<UserBuyFromExchangeBody>(defaultPurchaseSecuritiesForm);
	const [portfolio, setPortfolio] = useState<Portfolio>();
	const [showSuccess, setShowSuccess] = useState(false);

	useEffect(() => {
		if (isOpen) {
			readSecuritiesAndPortfolio().then().catch();
		}
	}, [isOpen]);

	function resetAndClose(): void {
		setForm(defaultPurchaseSecuritiesForm);
		setShowSuccess(false);
		props.onClose();
	}

	async function readSecuritiesAndPortfolio(): Promise<void> {
		props.dispatch(incrementLoading());

		try {
			const res = await new MarketApi(getConfig(token)).marketGetSecuritiesForMarketGet({
				isActive: MarketGetSecuritiesForMarketGetIsActiveEnum.True,
				marketIdentifier: MarketIdentifier.RealEstateSecuritiesPlayMoneyVancouver,
			});

			setSecurities(res);
		} catch (e) {
			props.dispatch(addError(await e.json()));
			props.dispatch(decrementLoading());
		}

		try {
			const portfolioRes = await new MarketApi(getConfig(token)).marketGetPortfolioGet({marketIdentifier: MarketIdentifier.RealEstateSecuritiesPlayMoneyVancouver});

			setPortfolio(portfolioRes);
		} catch (e) {
			props.dispatch(addError(await e.json()))
		}

		props.dispatch(decrementLoading());

	}

	/**
	 * find the index of the selected security from the
	 * drop down and then select the correct security
	 *
	 * @param e
	 */
	function onSecurityChange(e): void {
		const foundSecurity: number = findIndex(securities, {_id: e.target.value});
		setForm({
			...form,
			security: securities[foundSecurity],
		});
	}

	/**
	 * quantity on change
	 *
	 * @param e
	 */
	function onNumberChange(e): void {
		let v = e.floatValue;
		if (v > 10000) {
			v = 10000;
		}

		setForm({
			...form,
			quantity: v,
		})
	}

	async function purchaseSecurities(): Promise<void> {
		props.dispatch(incrementLoading());

		try {
			const res = await new MarketApi(getConfig(token)).marketUserBuyFromExchangePost({
				userBuyFromExchangeBody: {
					security: form.security,
					quantity: Math.round(form.quantity * parseFloat(process.env.REACT_APP_SECURITY_MULTIPLIER)),
				},
			});

			props.dispatch(updateSidebarStandings());
			setShowSuccess(true);

		} catch (e) {
			props.dispatch(addError(await e.json()));
		}

		props.dispatch(decrementLoading());
	}

	/**
	 * calculate the total amount of the selected security that is already owned
	 * to be displayed in the bottom part of the form.
	 *
	 * Accounts for expectedTrue & counts both owned & invested of that security type
	 *
	 */
	let amountOfSelectedSecurityOwnedAbove: number = 0;
	let amountOfSelectedSecurityOwnedBelow: number = 0;
	if (portfolio && form.security) {
		const ownedSelectedSecurity: PortfolioSecurity = portfolio.securities[form.security._id];
		amountOfSelectedSecurityOwnedAbove = ownedSelectedSecurity.trueOwned;
		amountOfSelectedSecurityOwnedBelow = ownedSelectedSecurity.falseOwned;
	}

	const q: boolean = form.quantity as any !== "" && form.quantity != undefined && form.quantity != 0;

	return (
		<React.Fragment>
			{q && form.security && (
				<GenericSuccessModal
					isOpen={showSuccess}
					callback={resetAndClose}
					title="Purchase Complete"
					body={`${getQty(form.quantity, true)} of both above & below market value '${form.security.name}' securities have been added to your account at the cost of ${formatCurrency(getQty(form.quantity))}. Go to the "Holdings" page in the "My Portfolio" section to see your updated holdings.`}
				/>
			)}

			<Modal
				isOpen={isOpen}
				fade={true}
				centered={true}
				contentClassName="px-3"
				toggle={resetAndClose}
			>
				<ModalHeader toggle={resetAndClose}>Purchase Securities From Market</ModalHeader>

				<ModalBody>
					<div className="mb-3">
						<Label for="security">Select Security</Label>
						<Input type="select" id="security" name="security" placeholder="Select Security"
						       onChange={onSecurityChange} value={form.security ? form.security._id : -1}>
							<option value={-1} disabled>Select Security</option>
							<hr/>
							{securities.map((s: Security, i: number) => {
								return (
									<option value={s._id} key={`security-${i}`}>{s.name}</option>
								);
							})}
						</Input>
					</div>

					<div className="mb-3">
						<Label for="quantity">Enter Quantity</Label>
						<Row className="mb-3">
							<Col xs={12} sm={6} className="mb-2 mb-sm-0">
								<NumberFormat
									allowLeadingZeros={false}
									placeholder="Quantity"
									value={form ? form.quantity : ""}
									customInput={Input}
									thousandSeparator={true}
									decimalScale={0}
									onValueChange={onNumberChange}
									allowNegative={false}
								/>
							</Col>
							<Col xs={12} sm={6}
							     className="d-flex align-items-center justify-content-center justify-content-sm-start">
								<p className="mb-0">
									<span className="text-muted">
										{`x 1,000 = `}
									</span>
									{form.quantity ? getQty(form.quantity, true) : ""}
								</p>
							</Col>
						</Row>
					</div>

					<div className="mb-3">
						<Label for="quantity">Cost</Label>

						<p className="mb-0">
							<span>
								{form.quantity ? formatCurrency(getQty(form.quantity)) : "-"}
							</span>
						</p>
					</div>

					{q && form.security && (
						<div className="mb-3">
							<div>
								{`You will receive ${getQty(form.quantity, true)} of both above & below market value '${form.security.name}' securities.`}
							</div>
							<hr/>
						</div>
					)}

					{(q && form.security && portfolio) && (
						<div>
							<Row style={{fontSize: "11pt"}} className="mb-3">
								<Col xs={12} sm={6} className="mb-4 mb-sm-0">
									<div className="d-flex justify-content-between">
										<span className="mr-2">
											{`Your quantity of above market value ${form.security ? form.security.name : ""} securities`}
										</span>
										<span>
											{numberWithCommas(amountOfSelectedSecurityOwnedAbove)}
										</span>
									</div>

									<div className="d-flex justify-content-between text-muted">
										<span className="mr-2">
											This purchase
										</span>
										<span className="border-bottom border-dark">
											+{getQty(form.quantity, true)}
										</span>
									</div>

									<div className="d-flex justify-content-between mt-2">
										<span className="mr-2">
											{`Your quantity of above market value ${form.security ? form.security.name : ""} securities after this purchase`}
										</span>
										<span>
											{numberWithCommas(amountOfSelectedSecurityOwnedAbove + (getQty(form.quantity) as number))}
										</span>
									</div>
								</Col>

								<Col xs={12} sm={6}>
									<div className="d-flex justify-content-between">
										<span className="mr-2">
											{`Your quantity of below market value ${form.security ? form.security.name : ""} securities`}
										</span>
										<span>
											{numberWithCommas(amountOfSelectedSecurityOwnedBelow)}
										</span>
									</div>

									<div className="d-flex justify-content-between text-muted">
										<span className="mr-2">
											This purchase
										</span>
										<span className="border-bottom border-dark">
											+{getQty(form.quantity, true)}
										</span>
									</div>

									<div className="d-flex justify-content-between mt-2">
										<span className="mr-2">
											{`Your quantity of below market value ${form.security ? form.security.name : ""} securities after this purchase`}
										</span>
										<span>
											{numberWithCommas(amountOfSelectedSecurityOwnedBelow + (getQty(form.quantity) as number))}
										</span>
									</div>
								</Col>
							</Row>

							<hr/>

							<Row style={{fontSize: "11pt"}}>
								<Col xs={12} sm={{offset: 6, size: 6}}>
									<div className="d-flex justify-content-between">
										<span className="mr-2">
											{`Your balance before this purchase`}
										</span>
										<span>
											{formatCurrency(portfolio.availableMoney)}
										</span>
									</div>

									<div className="d-flex justify-content-between text-muted">
										<span className="mr-2">
											This purchase
										</span>
										<span className="border-bottom border-dark">
											-{formatCurrency(getQty(form.quantity))}
										</span>
									</div>

									<div className="d-flex justify-content-between mt-2">
										<span className="mr-2">
											{`Your balance after this purchase`}
										</span>
										<span className={((portfolio.availableMoney - (getQty(form.quantity) as number)) < 0) ? "text-danger" : ""}>
											{formatCurrency(portfolio.availableMoney - (getQty(form.quantity) as number))}
										</span>
									</div>
								</Col>
							</Row>
						</div>
					)}
				</ModalBody>

				<ModalFooter>
					<Button color="materialBlue" onClick={purchaseSecurities}>
						Purchase
					</Button>
				</ModalFooter>
			</Modal>
		</React.Fragment>
	);
};

function getQty(x: number, asString: boolean = false): number | string {
	let q: number = Math.round(x * parseFloat(process.env.REACT_APP_SECURITY_MULTIPLIER));

	if (isNaN(q)) {
		q = 0;
	}

	return asString ? numberWithCommas(q) : q;
}

export default connect((store: IStore, props: IPurchaseSecuritiesFromMarketModalProps) => {
	return {
		token: store.metaStore.token,
		...props,
	}
})(PurchaseSecuritiesFromMarketModal);
