import React from "react";
import BootstrapTable from 'react-bootstrap-table-next';
import {
	expectedTrueToAboveBelow,
	formatCurrency,
	momentStringFromMilliseconds,
	numberWithCommas,
	parseOrderTypeToString
} from "../utils/formatters";
import {
	IColumn,
	whiteSpaceCellFormatter
} from "../utils/tableCellHTMLFormatters";
import {Trade} from "client";

export interface ITradingHistoryTableRow {
	raw: Trade,
	date: number;
	action: string;
	listingType: string;
	propertyType: string;
	pricePerSecurity: number;
	expectedTrue: boolean;
	quantity: number;
	moneyTraded: number;
	expirationDate: number;
}

interface ITradingHistoryTableProps {
	data: Array<ITradingHistoryTableRow>
}

const TradingHistoryTable: React.FC<ITradingHistoryTableProps> = (props: ITradingHistoryTableProps) => {

	const {data} = props;

	return (
		<BootstrapTable
			bootstrap4={true}
			hover={true}
			keyField="id"
			data={data ? data : []}
			columns={columns}
			bordered={false}
			condensed={true}
			noDataIndication={data === undefined ? "Loading..." : "You currently have no trading history."}
		/>
	);
};

const columns: Array<IColumn> = [
	{dataField: "date", text: "Date", formatter: momentStringFromMilliseconds, sort: true},
	{dataField: "action", text: "Action", sort: true},
	{dataField: "listingType", text: "Listing Type", formatter: parseOrderTypeToString, sort: true, classes: "text-capitalize"},
	{dataField: "propertyType", text: "Security", formatter: whiteSpaceCellFormatter, sort: true},
	{dataField: "pricePerSecurity", text: "Price Per Security", formatter: formatCurrency, sort: true, headerAlign: "right", classes: "text-right"},
	{dataField: "expectedTrue", text: "Above/Below Market Value", formatter: expectedTrueToAboveBelow, sort: true, headerAlign: "right", classes: "text-right"},
	{dataField: "quantity", text: "Quantity", formatter: numberWithCommas, sort: true, headerAlign: "right", classes: "text-right"},
	{dataField: "moneyTraded", text: "Total", formatter: formatCurrency, sort: true, headerAlign: "right", classes: "text-right"},
	{dataField: "expirationDate", text: "Expiration Date", formatter: momentStringFromMilliseconds, sort: true, headerAlign: "right", classes: "text-right"},
];

export default TradingHistoryTable;
