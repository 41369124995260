import React from "react";
import BootstrapTable from 'react-bootstrap-table-next';
import {Holding, Order, PortfolioSecurity} from "client";
import {decimalAsPercent, formatCurrency, momentStringFromMilliseconds, numberWithCommas} from "../utils/formatters";
import {
	createTradingTableActionButton, IColumn,
	whiteSpaceCellFormatter
} from "../utils/tableCellHTMLFormatters";

export interface IHoldingsBreakdownTableRow {
	raw: Holding,
	propertyType: string;
	expirationDate: number;
	priceAtExpiration: number;
	aboveMarketAvailable: number;
	belowMarketAvailable: number;
	aboveMarketInvested: number;
	belowMarketInvested: number;
	total: number;
	totalShare: number;
}

interface IHoldingsBreakdownTableProps {
	data?: Array<IHoldingsBreakdownTableRow>;
}

const HoldingsBreakdownTable: React.FC<IHoldingsBreakdownTableProps> = (props: IHoldingsBreakdownTableProps) => {

	const {data} = props;

	return (
		<BootstrapTable
			bootstrap4={true}
			hover={true}
			keyField="id"
			data={data ? data : []}
			columns={columns}
			bordered={false}
			condensed={true}
			noDataIndication={data === undefined ? "Loading..." : "You currently have no holdings."}
		/>
	);
};

const columns: Array<IColumn> = [
	{dataField: "propertyType", text: "Security", formatter: whiteSpaceCellFormatter, sort: true},
	{dataField: "expirationDate", text: "Expiration Date", formatter: momentStringFromMilliseconds, sort: true, headerAlign: "right", classes: "text-right"},
	{dataField: "priceAtExpiration", text: "Price At Expiration", formatter: formatCurrency, sort: true, headerAlign: "right", classes: "text-right"},
	{dataField: "aboveMarketAvailable", text: "Above Market Available", formatter: numberWithCommas, sort: true, headerAlign: "right", classes: "text-right"},
	{dataField: "belowMarketAvailable", text: "Below Market Available", formatter: numberWithCommas, sort: true, headerAlign: "right", classes: "text-right"},
	{dataField: "aboveMarketInvested", text: "Above Market Invested", formatter: numberWithCommas, sort: true, headerAlign: "right", classes: "text-right"},
	{dataField: "belowMarketInvested", text: "Below Market Invested", formatter: numberWithCommas, sort: true, headerAlign: "right", classes: "text-right"},
	{dataField: "total", text: "Total", formatter: numberWithCommas, sort: true, headerAlign: "right", classes: "text-right"},
	{dataField: "totalShare", text: "Total Share", formatter: decimalAsPercent, sort: true, headerAlign: "right", classes: "text-right"},
];

export default HoldingsBreakdownTable;
