import React, {ReactNode, useEffect, useState} from "react";
import {connect} from "react-redux";
import {IStore} from "../redux/defaultStore";
import {Card, CardBody, Container} from "reactstrap";
import {addError, decrementLoading, incrementLoading} from "../redux/meta/MetaActions";
import {MarketApi, MarketIdentifier, PortfolioSecurity} from "client";
import {getConfig} from "../services/clientApis";
import values from "lodash.values";
import PayoutProjectionRow from "../Components/PayoutProjectionRow";
import {formatCurrency} from "../utils/formatters";

interface IPayoutProjectionPageProps {
	token?: string;
	dispatch?: any;
}

const PayoutProjectionPage: React.FC<IPayoutProjectionPageProps> = (props: IPayoutProjectionPageProps) => {

	const {token} = props;
	const [securities, setSecurities] = useState<Array<PortfolioSecurity>>([]);
	const [toggles, setToggles] = useState([]); // track which, if either, checkbox is selected for each security

	useEffect(() => {
		readPortfolio().then().catch();
	}, []);

	async function readPortfolio(): Promise<void> {
		props.dispatch(incrementLoading());

		try {
			const res = await new MarketApi(getConfig(token)).marketGetPortfolioGet({marketIdentifier: MarketIdentifier.RealEstateSecuritiesPlayMoneyVancouver});

			setSecurities(values(res.securities));
			setToggles(new Array(Object.keys(res.securities).length).fill(undefined));

		} catch (e) {
			props.dispatch(addError(await e.json()))
		}

		props.dispatch(decrementLoading());
	}

	function createSecurityRow(_securities: Array<PortfolioSecurity>): ReactNode {
		return _securities.map((_security: PortfolioSecurity, i: number) => {

			function onChangeHelper(t: any): void {
				let _toggles = [...toggles]; // shallow copy so we can edit the array easily
				_toggles[i] = t; // update value
				setToggles(_toggles); // save
			}

			return (
				<div key={i} className="mb-4">
					<PayoutProjectionRow
						portfolioSecurity={_security}
						toggle={toggles[i]}
						i={i}
						amountAbove={_security.trueOwned + _security.trueInvested}
						amountBelow={_security.falseOwned + _security.falseInvested}
						onChange={onChangeHelper}
					/>
					<hr/>
				</div>
			);
		});
	}

	function calculateTotal(): string {
		let total: number = 0;
		let i: number;
		for (i = 0; i < securities.length; i++) {
			if (toggles[i] !== undefined) {
				total += securities[i][toggles[i] === true ? "trueOwned" : "falseOwned"];
				total += securities[i][toggles[i] === true ? "trueInvested" : "falseInvested"];
			}
		}

		return formatCurrency(total);
	}

	return (
		<Container className="my-5 md-fluid-container">
			<Card>
				<CardBody>
					<h2>Payout Projection</h2>
					<p>
						This page should show a list of all the securities you own with the name, shares you own at below market value, shares you own at above market value, the expiry date, the money that should be made at payout for above and below.
					</p>

					<hr/>

					<div style={{overflowX: "scroll"}}>
						{createSecurityRow(securities)}
					</div>

					<div className="d-flex justify-content-between">
						<span>
							<h3 className="text-muted">
								Total
							</h3>
						</span>

						<span>
							<h3>
								{calculateTotal()}
							</h3>
						</span>
					</div>
				</CardBody>
			</Card>
		</Container>
	)
};

export default connect((store: IStore, props: IPayoutProjectionPageProps) => {
	return {
		token: store.metaStore.token,
		...props,
	}
})(PayoutProjectionPage);
