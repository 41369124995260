import React, {ReactNode, useEffect, useState} from "react";
import {Card, CardBody, Col, Container, Row} from "reactstrap";
import {Article, RealestateSecuritiesApi, UsefulLink} from "client";
import {addError, decrementLoading, incrementLoading} from "../redux/meta/MetaActions";
import {connect} from "react-redux";
import {IStore} from "../redux/defaultStore";
import UsefulLinkDisplay from "../Components/UsefulLink";
import {getConfig} from "../services/clientApis";

interface IUsefulLinksProps {
	token?: string;
	dispatch?: any;
}

const UsefulLinks: React.FC<IUsefulLinksProps> = (props: IUsefulLinksProps) => {

	const {token} = props;
	const [usefulLinks, setUsefulLinks] = useState<Array<UsefulLink>>([]);

	useEffect(() => {
		readUsefulLinks().then().catch();
	}, []);

	/**
	 * Call api and get the useful links
	 *
	 */
	async function readUsefulLinks(): Promise<void> {
		props.dispatch(incrementLoading());

		try {
			const res = await new RealestateSecuritiesApi(getConfig(token)).realestateSecuritiesUsefulLinkListGet();
			setUsefulLinks(res);
		} catch (e) {
			props.dispatch(addError(await e.json()));
		}

		props.dispatch(decrementLoading());
	}

	/**
	 * Iterate the articles and return a component for each
	 *
	 * @param uls
	 */
	function mapUsefulLinks(uls: Array<UsefulLink> = []): ReactNode {
		return uls.map((ul: UsefulLink, i: number) => {
			return (
				<Col xs={12} sm={6} md={6} lg={4} key={`useful-link-${i}`} className="mb-4">
					<UsefulLinkDisplay usefulLink={ul}/>
				</Col>
			)
		});
	}

	return (
		<Container className="my-5">
			<Row className="flex-wrap">
				{mapUsefulLinks(usefulLinks)}
			</Row>
		</Container>
	);
};

export default connect((store: IStore, props: IUsefulLinksProps) => {
	return {
		token: store.metaStore.token,
		...props,
	}
})(UsefulLinks);
