import React, {useEffect, useState} from "react";
import {connect} from "react-redux";
import {IStore} from "../redux/defaultStore";
import {addError, decrementLoading, incrementLoading} from "../redux/meta/MetaActions";
import HoldingsBreakdownTable, {IHoldingsBreakdownTableRow} from "../Components/HoldingsBreakdownTable";
import {Holding, MarketApi, MarketIdentifier, PortfolioSecurity} from "client";
import {getConfig} from "../services/clientApis";
import values from "lodash.values";
import moment from "moment";
import {Card, CardBody} from "reactstrap";

interface IHoldingsPageProps {
	token?: string;
	dispatch?: any;
}

const HoldingsPage: React.FC<IHoldingsPageProps> = (props: IHoldingsPageProps) => {

	const {token} = props;
	const [holdings, setHoldings] = useState<Array<IHoldingsBreakdownTableRow>>();

	useEffect(() => {
		readHoldings().then().catch();
	}, []);

	async function readHoldings(): Promise<void> {
		props.dispatch(incrementLoading());

		try {

			const r2 = await new MarketApi(getConfig(token)).marketGetHoldingsGet({marketIdentifier: MarketIdentifier.RealEstateSecuritiesPlayMoneyVancouver});

			const securities: Array<IHoldingsBreakdownTableRow> = values(r2).map((s: Holding): IHoldingsBreakdownTableRow => {
				const total: number = s.trueOwned + s.falseOwned + s.trueInvested + s.falseInvested;

				return {
					raw: s,
					propertyType: s.security.name,
					expirationDate: s.security.expirationDate,
					priceAtExpiration: s.security.expectedValueAtExpiration as number,
					aboveMarketAvailable: s.trueOwned,
					belowMarketAvailable: s.falseOwned,
					aboveMarketInvested: s.trueInvested,
					belowMarketInvested: s.falseInvested,
					total,
					totalShare: s.totalShare,
				}
			});

			setHoldings(securities);

		} catch (e) {
			props.dispatch(addError(await e.json()))
		}

		props.dispatch(decrementLoading());
	}

	return (
		<div>
			<div className="my-5 px-1 px-lg-2">
				<Card>
					<CardBody>
						<h2 className="mb-4">
							Portfolio Breakdown
						</h2>
					</CardBody>

					<HoldingsBreakdownTable data={holdings}/>

				</Card>
			</div>
		</div>
	)
};

export default connect((store: IStore, props: IHoldingsPageProps) => {
	return {
		token: store.metaStore.token,
		...props,
	}
})(HoldingsPage);
