import React, {useEffect, useState} from "react";
import {connect} from "react-redux";
import {IStore} from "../redux/defaultStore";
import MarketSecuritiesTable, {IMarketSecuritiesTableRow} from "../Components/MarketSecuritiesTable";
import {addError, decrementLoading, incrementLoading} from "../redux/meta/MetaActions";
import {Card, CardBody} from "reactstrap";
import {
	MarketApi,
	MarketGetSecurityTotalsForMarketGetIsActiveEnum,
	MarketIdentifier,
	SecurityTotal
} from "client";
import {getConfig} from "../services/clientApis";
import cloneDeep from "lodash.clonedeep";

interface IMarketSecuritiesPageProps {
	token?: string;
	dispatch?: any;
}

const MarketSecuritiesPage: React.FC<IMarketSecuritiesPageProps> = (props: IMarketSecuritiesPageProps) => {

	const {token} = props;
	const [marketSecurities, setMarketSecurities] = useState<Array<IMarketSecuritiesTableRow>>();

	useEffect(() => {
		readSecurities().then().catch();
	}, []);

	async function readSecurities(): Promise<void> {
		props.dispatch(incrementLoading());

		try {
			const res = await new MarketApi(getConfig(token)).marketGetSecurityTotalsForMarketGet({
				isActive: MarketGetSecurityTotalsForMarketGetIsActiveEnum.True,
				marketIdentifier: MarketIdentifier.RealEstateSecuritiesPlayMoneyVancouver,
			});

			const data = cloneDeep(res.securityTotals);
			const dataForTable: Array<IMarketSecuritiesTableRow> = [];

			for (const d in data) {
				let s: SecurityTotal = data[d];
				dataForTable.push({
					raw: s,
					propertyType: s.security.name,
					expirationDate: s.security.expirationDate,
					priceAtExpiration: s.security.expectedValueAtExpiration as number,
					aboveMarketSharesIssued: s.totalTrueShare,
					belowMarketSharesIssued: s.totalFalseShare,
					totalIssued: s.totalShare,
				})
			}

			setMarketSecurities(dataForTable);

		} catch (e) {
			props.dispatch(addError(await e.json()))
		}

		props.dispatch(decrementLoading());
	}

	return (
		<div>
			<div className="my-5 px-1 px-lg-2">
				<Card>
					<CardBody>
						<h2 className="mb-4">
							Market Securities
						</h2>
					</CardBody>

					<MarketSecuritiesTable data={marketSecurities}/>
				</Card>
			</div>
		</div>
	)
};

export default connect((store: IStore, props: IMarketSecuritiesPageProps) => {
	return {
		token: store.metaStore.token,
		...props,
	}
})(MarketSecuritiesPage);
