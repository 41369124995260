import React from "react";
import {formatCurrency, numberWithCommas} from "../utils/formatters";

interface IPayoutProjectionToggleProps {
	title: string;
	active: boolean;
	quantity: number;
	i: number;
	onChange(): void;
}

const PayoutProjectionToggle: React.FC<IPayoutProjectionToggleProps> = (props: IPayoutProjectionToggleProps) => {

	const {title, active, quantity, i} = props;

	const uniqueId: string = title + "_" + i;
	const textClass: string = active ? "text-dark" : "text-muted";

	return (
		<div className="d-flex">
			<div className="d-flex align-items-center mr-3">
				<input
					type="checkbox"
					name="payoutProjectionToggle"
					id={uniqueId}
					checked={active}
					onChange={props.onChange}
				/>
				<label htmlFor={uniqueId}/>
			</div>

			<div>
				<p className="text-muted text-right mb-0" style={{fontSize: "0.8rem"}}>
					{title}
				</p>

				<p className={textClass + " text-right mb-0"}>
					{numberWithCommas(quantity)}
				</p>

				<hr className="border-dark my-1"/>

				<p className={textClass + " text-right mb-0"}>
					{formatCurrency(quantity)}
				</p>
			</div>
		</div>
	);
};

export default PayoutProjectionToggle;
