
import React from "react";
import BootstrapTable from 'react-bootstrap-table-next';
import {Order, OrderInformation} from "client";
import {
	expectedTrueToAboveBelow,
	formatCurrency,
	numberWithCommas,
	parseActiveInactive,
	parseOrderTypeToString
} from "../utils/formatters";
import {
	createTradingTableActionButton, IColumn,
	whiteSpaceCellFormatter
} from "../utils/tableCellHTMLFormatters";

export interface IHistoryOfOrdersTableRow {
	raw: OrderInformation,
	listingType: string;
	propertyType: string;
	date: string;
	amount: number;
	pricePerSecurity: number;
	moneyTransferred: number;
	active: boolean;
	expectedTrue: boolean;
}

interface IHistoryOfOrdersTableProps {
	data: Array<IHistoryOfOrdersTableRow>
}

const HistoryOfOrdersTable: React.FC<IHistoryOfOrdersTableProps> = (props: IHistoryOfOrdersTableProps) => {

	const {data} = props;

	return (
		<BootstrapTable
			bootstrap4={true}
			hover={true}
			keyField="id"
			data={data ? data : []}
			columns={columns}
			bordered={false}
			condensed={true}
			noDataIndication={data === undefined ? "Loading..." : "You have no order history yet."}
			defaultSorted={[{dataField: "active", order: "asc"}]}
		/>
	);
};

const columns: Array<IColumn> = [
	{dataField: "listingType", text: "Trade Type", formatter: parseOrderTypeToString, sort: true, classes: "text-capitalize"},
	{dataField: "propertyType", text: "Security", formatter: whiteSpaceCellFormatter, sort: true},
	{dataField: "date", text: "Date", sort: true, headerAlign: "right", classes: "text-right"},
	{dataField: "amount", text: "Amount", formatter: numberWithCommas, sort: true, headerAlign: "right", classes: "text-right"},
	{dataField: "pricePerSecurity", text: "Price", formatter: formatCurrency, sort: true, headerAlign: "right", classes: "text-right"},
	{dataField: "moneyTransferred", text: "Money Transferred", formatter: formatCurrency, sort: true, headerAlign: "right", classes: "text-right"},
	{dataField: "active", text: "Active / Inactive", formatter: parseActiveInactive, sort: true, headerAlign: "right", classes: "text-right"},
	{dataField: "expectedTrue", text: "Above/Below Market Value", formatter: expectedTrueToAboveBelow, sort: true, headerAlign: "right", classes: "text-right"},
];

export default HistoryOfOrdersTable;
