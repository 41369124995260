import React, {useState} from "react";
import {Card, CardBody, Tooltip} from "reactstrap";

interface IStatisticsCardBProps {
	title: string;
	value: string;
	toolTip: string;
}

const StatisticsCardB: React.FC<IStatisticsCardBProps> = (props: IStatisticsCardBProps) => {

	const {title, value, toolTip} = props;
	const [showToolTip, setShowToolTip] = useState(false);

	function enableToolTip(): void {
		setShowToolTip(true);
	}

	function disableToolTip(): void {
		setShowToolTip(false);
	}

	function toggleTooltip(): void {
		setShowToolTip(!showToolTip);
	}

	const id: string = title.replace(/\s/g, "");

	return (
		<Card
			className="h-100 w-100 d-flex"
			onMouseOver={enableToolTip}
			onMouseLeave={disableToolTip}
			id={id}
		>
			<Tooltip placement="top" isOpen={showToolTip} target={id} toggle={toggleTooltip}
			         style={{fontSize: "1.1rem"}}>
				{toolTip}
			</Tooltip>

			<CardBody>
				<h5>
					{title}
				</h5>

				<h4 className="text-muted">
					{value}
				</h4>
			</CardBody>
		</Card>
	);
};

export default StatisticsCardB;
