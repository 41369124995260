import React, {useEffect} from "react";
import "./App.css";
import {PersistGate} from "redux-persist/integration/react";
import {persistor} from "./redux";
import {BrowserRouter, Redirect, Route, Switch} from "react-router-dom";
import SidebarHelper from "./Components/Sidebar/SidebarHelper";
import {connect} from "react-redux";
import {IStore} from "./redux/defaultStore";
import DemoIntro from "./Pages/DemoIntro";
import Instructions from "./Pages/Instructions";
import NewsFeed from "./Pages/NewsFeed";
import RealEstateMarket from "./Pages/RealEstateMarkets";
import UsefulLinks from "./Pages/UsefulLinks";
import {InformationCenterNav, InternalMarketNav, PortfolioNav, TradingNav} from "./Components/TabNavigators";
import BuyOrdersPage from "./Pages/BuyOrdersPage";
import SellOrdersPage from "./Pages/SellOrdersPage";
import MyTradingHistory from "./Pages/MyTradingHistory";
import MyOrders from "./Pages/MyOrdersPage";
import Statistics from "./Pages/StatisticsPage";
import MarketTradingHistoryPage from "./Pages/MarketTradingHistoryPage";
import MarketSecuritiesPage from "./Pages/MarketSecuritiesPage";
import HoldingsPage from "./Pages/HoldingsPage";
import FinancialSummaryPage from "./Pages/FinancialSummaryPage";
import PayoutProjectionPage from "./Pages/PayoutProjectionPage";
import Login from "./Pages/Login";
import AuthenticatedRoute from "./Pages/AuthenticatedRoute";
import Register from "./Pages/Register";
import PurchaseSecuritiesPage from "./Pages/PurchaseSecuritiesPage";
import ToastManager from "./Components/ToastManager";
import {useLocation} from "react-router-dom"
import {updateSidebarStandings} from "./redux/financialStanding/FinancialStandingActions";
import PortfolioIntervalChecker from "./Components/PortfolioIntervalChecker";

/* Put regular Routes & header / foots etc as children inside of SidebarHelper below,
for absolutes like Loading, errors, etc, make them siblings SidebarHelper */

const App: React.FC = (props: any) => {

	return (
		<PersistGate persistor={persistor}>
			<BrowserRouter>

				<PortfolioIntervalChecker/>

				<SidebarHelper>
					<main className="mh-100">

						<ToastManager/>

						{/** Header/Page specific Tab Navigation **/}
						<Route path="/my-account" render={PortfolioNav}/>
						<Route path="/trading" render={TradingNav}/>
						<Route path="/internal-market" render={InternalMarketNav}/>
						<Route path="/information-center" render={InformationCenterNav}/>

						<Switch>
							<Route exact path="/" component={Login}/>
							<Route exact path="/sign-up" component={Register}/>
							{/*<AuthenticatedRoute exact path="/demo-intro" component={DemoIntro}/>*/}
							<AuthenticatedRoute exact path="/instructions" component={Instructions}/>

							<AuthenticatedRoute exact path="/my-account/portfolio" component={HoldingsPage}/>
							<AuthenticatedRoute exact path="/my-account/financial-summary" component={FinancialSummaryPage}/>
							<AuthenticatedRoute exact path="/my-account/payout-projection" component={PayoutProjectionPage}/>
							<AuthenticatedRoute exact path="/my-account/trading-history" component={MyTradingHistory}/>
							<Redirect from="/my-portfolio" to="/my-account/portfolio"/>

							<AuthenticatedRoute exact path="/trading/buy-orders" component={BuyOrdersPage}/>
							<AuthenticatedRoute exact path="/trading/sell-orders" component={SellOrdersPage}/>
							<AuthenticatedRoute exact path="/trading/purchase-securities-from-market" component={PurchaseSecuritiesPage}/>
							<AuthenticatedRoute exact path="/trading/my-orders" component={MyOrders}/>
							<Redirect from="/trading" to="/trading/buy-orders"/>

							<AuthenticatedRoute exact path="/internal-market/statistics" component={Statistics}/>
							<AuthenticatedRoute exact path="/internal-market/trading-history" component={MarketTradingHistoryPage}/>
							<AuthenticatedRoute exact path="/internal-market/securities" component={MarketSecuritiesPage}/>
							<Redirect from="/internal-market" to="/internal-market/statistics"/>

							<AuthenticatedRoute exact path="/information-center/news" component={NewsFeed}/>
							<AuthenticatedRoute exact path="/information-center/real-estate-market" component={RealEstateMarket}/>
							<AuthenticatedRoute exact path="/information-center/useful-links" component={UsefulLinks}/>
							<Redirect from="/information-center" to="/information-center/news"/>

							<Redirect to="/"/>
						</Switch>
					</main>

				</SidebarHelper>
			</BrowserRouter>
		</PersistGate>
	);
};

export default connect((store: IStore, props: any) => {
	return {
		...props,
	}
})(App);
