import React, {ChangeEventHandler, useEffect, useState} from "react";
import {connect} from "react-redux";
import {IStore} from "../redux/defaultStore";
import {Card, CardBody, Col, Container, Input, Label, Row} from "reactstrap";
import {addError, incrementLoading} from "../redux/meta/MetaActions";
import {FinancialSummary, MarketApi, MarketIdentifier} from "client";
import {getConfig} from "../services/clientApis";
import FinancialSummaryComponent from "../Components/FinancialSummaryComponent";
import moment from "moment";

interface IFinancialSummaryPageProps {
	token?: string;
	dispatch?: any;
}

interface IDateRangeForm {
	startDate: number;
	endDate: number;
}

const FinancialSummaryPage: React.FC<IFinancialSummaryPageProps> = (props: IFinancialSummaryPageProps) => {

	const {token} = props;
	const [fs, setFs] = useState<FinancialSummary>();
	const [form, setForm] = useState<IDateRangeForm>({
		startDate: moment().valueOf(),
		endDate: moment().valueOf(),
	});

	useEffect(() => {
		readFinancialSummary().then().catch();
	}, [JSON.stringify(form)]);

	async function readFinancialSummary(): Promise<void> {
		props.dispatch(incrementLoading());

		try {
			const res = await new MarketApi(getConfig(token)).marketGetFinancialSummaryGet({
				marketIdentifier: MarketIdentifier.RealEstateSecuritiesPlayMoneyVancouver,
				startDate: form.startDate,
				endDate: form.endDate,
			});

			setFs(res);
		} catch (e) {
			props.dispatch(addError(await e.json()))
		}
	}

	function datePickOnChange(key: keyof IDateRangeForm): ChangeEventHandler<HTMLInputElement> {
		return (e) => {
			setForm({
				...form,
				[key]: moment(e.target.value, "YYYY-MM-DD").valueOf(),
			})
		}
	}

	return (
		<Container className="my-5 md-fluid-container">
			<Card>
				<CardBody>
					<div className="mb-4">
						<h2>Financial Summary</h2>
						<p>
							Here you can see a breakdown of all activities that have had an effect on your balance &
							profit/loss in the market in a specific time range.
							By default, the past day is selected. The start date will include transactions starting at
							12:00am on the selected day, and the end date will go to 11:59pm - or match the current time
							if the selected end date is today.
							Click or tap on a balance with an arrow next to it to view a detailed breakdown. Then, you
							can click on a specific transaction to view its full details.
						</p>
					</div>

					<Row style={{maxWidth: 740}} className="mb-4">
						<Col xs={12} sm={6} className="mb-3 mb-sm-0">
							<Label>Start Date</Label>
							<Input type="date" value={moment(form.startDate).format("YYYY-MM-DD")}
							       onChange={datePickOnChange("startDate")}/>
						</Col>

						<Col xs={12} sm={6} className="mb-3 mb-sm-0">
							<Label>End Date</Label>
							<Input type="date" value={moment(form.endDate).format("YYYY-MM-DD")}
							       onChange={datePickOnChange("endDate")}/>
						</Col>

						{((form.startDate > form.endDate) || (form.startDate > moment().valueOf())) && (
							<Col xs={12} className="mt-3">
								<p className="mb-0 text-danger font-italic">
									Selected start date is later than selected end date, or the selected start date is in the future. No data points will exist.
								</p>
							</Col>
						)}
					</Row>

					{fs && (
						<div>
							<FinancialSummaryComponent financialSummary={fs}/>
						</div>
					)}
				</CardBody>
			</Card>
		</Container>
	)
};

export default connect((store: IStore, props: IFinancialSummaryPageProps) => {
	return {
		token: store.metaStore.token,
		...props,
	}
})(FinancialSummaryPage);
