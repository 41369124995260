import React from "react";
import {PortfolioSecurity} from "client";
import moment from "moment";
import PayoutProjectionToggle from "./PayoutProjectionToggle";
import {formatCurrency} from "../utils/formatters";

interface IPayoutProjectionRowProps {
	portfolioSecurity: PortfolioSecurity;
	toggle: any;
	i: number;
	amountAbove: number;
	amountBelow: number;
	onChange(t: any): void;
}

const PayoutProjectionRow: React.FC<IPayoutProjectionRowProps> = (props: IPayoutProjectionRowProps) => {

	const {portfolioSecurity, toggle, i, amountAbove, amountBelow} = props;

	function onChangeIntercept(base: boolean): () => void {
		return () => {
			if (toggle !== base) {
				props.onChange(base);
			} else {
				props.onChange(undefined);
			}
		}
	}

	const expiryDate: string = moment(portfolioSecurity.security.expirationDate).format("MMMM Do YYYY");

	return (
		<div className="w-100 d-inline-flex justify-content-between">
			<div style={{minWidth: 300, width: 300}}>
				<h6 className="mb-2">
					{portfolioSecurity.security.name}
				</h6>
				<p className="text-muted mb-1" style={{fontSize: "0.8rem"}}>
					{"Expires " + expiryDate}
				</p>
				{toggle !== undefined && (
					<p>
						{`On ${expiryDate} you will get ${formatCurrency(toggle === true ? portfolioSecurity.trueOwned : portfolioSecurity.falseOwned)} if the market value of '${portfolioSecurity.security.name}' is ${toggle === true ? "above" : "below"} ${formatCurrency(portfolioSecurity.security.expectedValueAtExpiration)}`}
					</p>
				)}
			</div>

			<div style={{minWidth: 400, width: 400}}>
				<div className="d-flex justify-content-end">
					<PayoutProjectionToggle
						title="Amount owned above"
						quantity={amountAbove}
						active={toggle === true}
						i={i}
						onChange={onChangeIntercept(true)}
					/>

					<div className="mx-3"/>

					<PayoutProjectionToggle
						title="Amount owned below"
						quantity={amountBelow}
						active={toggle === false}
						i={i}
						onChange={onChangeIntercept(false)}
					/>
				</div>
			</div>
		</div>
	);
};

export default PayoutProjectionRow;
