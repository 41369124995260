import React from "react";
import {Nav, Navbar, NavItem, UncontrolledTooltip} from "reactstrap";
import {NavLink, useHistory} from "react-router-dom";
import {
	FiHome,
	FiTag,
	FiUsers,
	FiBarChart,
	FiUserCheck,
	FiShare2,
	FiLogOut
} from "react-icons/fi";
import {connect} from "react-redux";
import {IStore} from "../../redux/defaultStore";
import {formatCurrency} from "../../utils/formatters";
import {logout, toggleSidebar} from "../../redux/meta/MetaActions";

interface ISidebarContentProps {
	availableBalance?: number;
	currentBalance?: number;
	dispatch?: any;
	sidebarOpen?: boolean;
}

const SidebarContent: React.FC<ISidebarContentProps> = (props: ISidebarContentProps) => {

	const {availableBalance, currentBalance, sidebarOpen} = props;
	const history = useHistory();

	/**
	 * Little utility function that takes an array of paths/urls and compares against location.pathname
	 * supplied by React Router to determine if we should show the nav link as "active" -
	 * for the cases where multiple pages become available after choosing 1 from the sidebar, ie. Information Center
	 *
	 * @param paths
	 */
	function activeHelper(paths: string[] = []): (match, location) => boolean {
		return (match, location) => {
			return paths.includes(location.pathname);
		}
	}

	function logoutClick(e: React.MouseEvent<HTMLAnchorElement>): void {
		e.preventDefault();
		props.dispatch(toggleSidebar(false));
		props.dispatch(logout());
		history.push("/");
	}

	function close(): void {
		if (sidebarOpen) {
			props.dispatch(toggleSidebar(false));
		}
	}

	return (
		<div className="w-100">
			<div className="bg-navMid p-3 d-flex justify-content-center">
				<img
					src={process.env.PUBLIC_URL + "/logos/logo_white.png"}
					style={{maxWidth: "60%"}}
				/>
			</div>

			<div className="py-3">
				<Navbar color="black" dark>
					<Nav navbar className="w-100">

						<div className="text-white mb-3">
							<h5 className="text-white mb-2">Financial Standing</h5>

							<p className="d-flex justify-content-between mb-0 financial-standing-item"
							   id="available_balance_indicator">
								<span className="text-muted mb-0 financial-standing-item-label">
									Available
								</span>
								<h6 className="skinny d-inline">
									{formatCurrency(availableBalance)}
								</h6>

								<UncontrolledTooltip placement="top" target="available_balance_indicator"
								                     style={{fontSize: "1rem"}}>
									Available Balance: The amount of money you have available to spend right now.
								</UncontrolledTooltip>
							</p>

							<p className="d-flex justify-content-between mb-0 financial-standing-item"
							   id="current_balance_indicator">
								<span className="text-muted mb-0 financial-standing-item-label">
									Current
								</span>
								<h6 className="skinny d-inline">
									{formatCurrency(currentBalance)}
								</h6>

								<UncontrolledTooltip placement="top" target="current_balance_indicator"
								                     style={{fontSize: "1rem"}}>
									Current Balance: The amount of money you have available to spend right now plus any
									money you have invested in active buy orders.
								</UncontrolledTooltip>
							</p>

						</div>

						<hr className="border-muted w-100 mt-0 mb-3"/>

						<div>
							<h5 className="text-white mb-2">Overview</h5>
							{/*<NavItem>*/}
							{/*	<NavLink exact to="/demo-intro"*/}
							{/*	         className="text-decoration-none nav-link d-flex align-items-center p-0 mb-2">*/}
							{/*		<FiHome*/}
							{/*			size="1.25rem"*/}
							{/*			style={{maxHeight: "20px"}}*/}
							{/*			className="mr-3"*/}
							{/*		/>*/}
							{/*		Demo Intro*/}
							{/*	</NavLink>*/}
							{/*</NavItem>*/}

							<NavItem>
								<NavLink exact to="/instructions"
								         className="text-decoration-none nav-link d-flex align-items-center p-0 mb-2"
								         onClick={close}
								>
									<FiTag
										size="1.25rem"
										style={{maxHeight: "20px"}}
										className="mr-3"
									/>
									Instructions
								</NavLink>
							</NavItem>
						</div>

						<div className="mb-3">
							<h5 className="text-white mb-2 mt-3">Demonstration</h5>
							<NavItem>
								<NavLink
									to="/my-account/portfolio"
									className="text-decoration-none nav-link d-flex align-items-center p-0 mb-2"
									isActive={activeHelper(["/my-account/portfolio", "/my-account/financial-summary", "/my-account/payout-projection", "/my-account/trading-history"])}
									onClick={close}
								>
									<FiUserCheck
										size="1.25rem"
										style={{maxHeight: "20px"}}
										className="mr-3"
									/>
									My Account
								</NavLink>
							</NavItem>

							<NavItem>
								<NavLink
									to="/trading/buy-orders"
									className="text-decoration-none nav-link d-flex align-items-center p-0 mb-2"
									isActive={activeHelper(["/trading/buy-orders", "/trading/sell-orders", "/trading/purchase-securities-from-market", "/trading/my-orders"])}
									onClick={close}
								>
									<FiUsers
										size="1.25rem"
										style={{maxHeight: "20px"}}
										className="mr-3"
									/>
									Trading
								</NavLink>
							</NavItem>

							<NavItem>
								<NavLink
									to="/internal-market/statistics"
									className="text-decoration-none nav-link d-flex align-items-center p-0 mb-2"
									isActive={activeHelper(["/internal-market/statistics", "/internal-market/trading-history", "/internal-market/securities"])}
									onClick={close}
								>
									<FiBarChart
										size="1.25rem"
										style={{maxHeight: "20px"}}
										className="mr-3"
									/>
									Internal Market
								</NavLink>
							</NavItem>

							<NavItem>
								<NavLink
									to="/information-center/news"
									className="text-decoration-none nav-link d-flex align-items-center p-0 mb-2"
									isActive={activeHelper(["/information-center/news", "/information-center/real-estate-market", "/information-center/useful-links"])}
									onClick={close}
								>
									<FiShare2
										size="1.25rem"
										style={{maxHeight: "20px"}}
										className="mr-3"
									/>
									Information Center
								</NavLink>
							</NavItem>
						</div>

						<hr className="border-muted w-100 mt-0 mb-3"/>

						<div>
							<NavItem>
								<a
									className="text-decoration-none nav-link d-flex align-items-center p-0 mb-2 text-danger"
									href="#"
									onClick={logoutClick}
								>
									<FiLogOut
										size="1.25rem"
										style={{maxHeight: 50}}
										className="mr-3"
									/>
									Log out
								</a>
							</NavItem>
						</div>

					</Nav>
				</Navbar>
			</div>
		</div>
	)
};

export default connect((store: IStore, props: ISidebarContentProps) => {
	return {
		availableBalance: store.financialStandingStore.availableBalance,
		currentBalance: store.financialStandingStore.currentBalance,
		sidebarOpen: store.metaStore.sidebarOpen,
		...props,
	}
})(SidebarContent);
