import React, {useEffect} from "react";
import {Button, Container, Nav, Navbar, NavItem} from "reactstrap";
import {FiMenu} from "react-icons/fi";
import {toggleSidebar} from "../redux/meta/MetaActions";
import {connect} from "react-redux";
import {IStore} from "../redux/defaultStore";

interface IHeaderProps {
	dispatch?: any;
	sidebarOpen?: boolean;
}

const Header: React.FC<IHeaderProps> = (props: IHeaderProps) => {

	/**
	 * Dispatch to redux to toggle open status,
	 * instead of tracking within this component.
	 *
	 */
	function toggleHelper(e): void {
		e.preventDefault();
		props.dispatch(toggleSidebar(!props.sidebarOpen));
	}

	return (
		<div className="hamburger-menu-helper">
			<Navbar light className="bg-materialBlue">
				<Nav navbar className="d-flex flex-row align-items-center justify-content-end w-100">
					{/*<div className="d-flex">*/}
					{/*	<NavItem className="mr-3">*/}
					{/*		Tab A*/}
					{/*	</NavItem>*/}

					{/*	<NavItem className="mr-3">*/}
					{/*		Tab B*/}
					{/*	</NavItem>*/}
					{/*</div>*/}

					{/*<div>*/}
					{/*	<Button color="link">*/}
					{/*		Login*/}
					{/*	</Button>*/}
					{/*</div>*/}

					<div className="header-toggle-icon">
						<a href="#" onClick={toggleHelper} className="text-light">
							<FiMenu
								size="1.75rem"
								style={{maxHeight: 50}}
							/>
						</a>
					</div>
				</Nav>
			</Navbar>
		</div>
	);
};

export default connect((store: IStore, props: IHeaderProps) => {
	return {
		sidebarOpen: store.metaStore.sidebarOpen,
		...props,
	}
})(Header);
