import React from "react";
import {Order, OrderInformation} from "client";
import {Button} from "reactstrap";
import {IBuyOrderTableRow} from "../Components/BuyOrdersTable";

export interface IColumn {
	dataField: string;
	text: string;
	formatter?: any;
	sort?: boolean;
	classes?: string;
	headerClasses?: string;
	headerAlign?: "left" | "center" | "right";
}

/**
 * adds className to cell to grant white-space: pre; css for line break
 *
 * @param cell
 */
export function whiteSpaceCellFormatter(cell: string): JSX.Element {
	return (
		<p className="white-space-pre">
			{cell}
		</p>
	)
}

/**
 * specifically for the 2 trading tables (buy & sell orders),
 * create button and add onClick with buttonTitle param
 *
 * @param buttonTitle
 */
export function createTradingTableActionButton(buttonTitle: string): (cell: (orderInfo: OrderInformation) => void, row: IBuyOrderTableRow) => JSX.Element {
	return (cell: (orderInfo: OrderInformation) => void, row: IBuyOrderTableRow) => {
		function helper(): void {
			cell(row.raw);
		}

		return (
			<div className="d-flex justify-content-center">
				<Button color="materialBlue" onClick={helper}>
					{buttonTitle}
				</Button>
			</div>
		);
	}
}
