import React from "react";
import PageTitleNav from "./PageTitleNav";

export const PortfolioNav: React.FC = () => {
	return (
		<PageTitleNav
			title="My Account"
			navTabs={[
				{path: "/my-account/portfolio", text: "Portfolio"},
				{path: "/my-account/financial-summary", text: "Financial Summary"},
				{path: "/my-account/payout-projection", text: "Payout Projection"},
				{path: "/my-account/trading-history", text: "My Trading History"},
			]}
		/>
	);
};

export const TradingNav: React.FC = () => {
	return (
		<PageTitleNav
			title="Trading"
			navTabs={[
				{path: "/trading/buy-orders", text: "Buy Orders"},
				{path: "/trading/sell-orders", text: "Sell Orders"},
				{path: "/trading/purchase-securities-from-market", text: "Purchase Securities From Market"},
				{path: "/trading/my-orders", text: "My Orders"},
			]}
		/>
	);
};

export const InternalMarketNav: React.FC = () => {
	return (
		<PageTitleNav
			title="Internal Market"
			navTabs={[
				{path: "/internal-market/statistics", text: "Statistics"},
				{path: "/internal-market/trading-history", text: "Trading History"},
				{path: "/internal-market/securities", text: "Securities"},
			]}
		/>
	);
};

export const InformationCenterNav: React.FC = () => {
	return (
		<PageTitleNav
			title="Information Center"
			navTabs={[
				{path: "/information-center/news", text: "News Feed"},
				{path: "/information-center/real-estate-market", text: "Real Estate Markets"},
				{path: "/information-center/useful-links", text: "Useful Links"},
			]}
		/>
	);
};
