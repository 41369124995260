import React from "react";
import {Button, Modal, ModalBody, ModalFooter, ModalHeader} from "reactstrap";

interface IGenericSuccessModalProps {
	isOpen: boolean;
	title?: string;
	body?: string;
	buttonText?: string;

	callback(): void;
}

const GenericSuccessModal: React.FC<IGenericSuccessModalProps> = (props: IGenericSuccessModalProps) => {

	const {isOpen, title, body, buttonText} = props;

	return (
		<Modal
			isOpen={isOpen}
			fade={true}
			centered={true}
			contentClassName="px-3"
			toggle={props.callback}
		>
			<ModalHeader>{title ? title : "Success"}</ModalHeader>

			<ModalBody>
				<p>
					{body ? body : 'Action completed successfully.'}
				</p>
			</ModalBody>

			<ModalFooter>
				<Button color="success" onClick={props.callback}>
					{buttonText ? buttonText : "Done"}
				</Button>
			</ModalFooter>
		</Modal>
	);
};

export default GenericSuccessModal;
