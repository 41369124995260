import React, {ReactNode} from "react";
import {CartesianGrid, ComposedChart, Line, Tooltip as ChartTip, XAxis, YAxis} from "recharts";
import {TimeFrame, TransactionalDataPoint} from "client";
import moment, {Moment} from "moment";
import {formatCurrency} from "../utils/formatters";

interface IStatisticsGraphProps {
	history: Array<TransactionalDataPoint>;
	timeFrame: TimeFrame;
}

const StatisticsGraph: React.FC<IStatisticsGraphProps> = (props: IStatisticsGraphProps) => {

	const {history, timeFrame} = props;

	function formatTimeStamp(tick: Moment): string {
		return moment(tick).format(timeFrame === TimeFrame.DAY ? "HH:mm" : "MMM Do YYYY");
	}

	function customTickLabel(label: any): any {
		const {x, y, stroke, payload} = label;

		return (
			<g transform={`translate(${x},${y})`}>
				<text x={0} y={0} dy={16} textAnchor="end" fill="#666"
				      transform="rotate(-35)">{formatTimeStamp(payload.value)}</text>
			</g>
		);
	}

	function formatMomentForChartTip(tick: Moment): string {
		return moment(tick).format("MMM Do YYYY HH:mm");
	}

	function chartTooltipFormatter(value: any, name: string, props: any): string {
		return name === "price" ? formatCurrency(value) : value;
	}

	function createChart(width: number, height: number): ReactNode {
		return (
			<React.Fragment>
				{history.length < 1 ? (
					<div>
						<p className="text-center font-italic text-muted">
							Not enough data points to display graph.
						</p>
					</div>
				) : (
					<ComposedChart
						width={width}
						height={height}
						data={history}
						margin={{top: 20, right: 30, left: 20, bottom: 5}}
					>
						<CartesianGrid strokeDasharray="3 3"/>
						<XAxis
							dataKey="date"
							scale="time"
							type="number"
							domain={["dataMin", "dataMax"]}
							tickFormatter={formatTimeStamp}
							tick={customTickLabel}
							height={100}
						/>

						<YAxis
							yAxisId="left"
							domain={[0, 1]}
							tickFormatter={formatCurrency}
						/>

						<Line yAxisId="left" type="monotone" dataKey="pricePerSecurity" stroke="#8884d8"
						      fill="#8884d8"/>

						<ChartTip
							labelFormatter={formatMomentForChartTip}
							formatter={chartTooltipFormatter}
						/>
					</ComposedChart>
				)}
			</React.Fragment>
		);
	}

	return (
		<div className="w-100 d-flex justify-content-center" style={{overflowX: "scroll"}}>
			<div className="d-block d-md-none graph-container" style={{overflowX: "scroll"}}>
				{createChart(460, 400)}
			</div>

			<div className="d-none d-md-block d-lg-none">
				{createChart(650, 400)}
			</div>

			<div className="d-none d-lg-block">
				{createChart(700, 450)}
			</div>
		</div>
	);
};

export default StatisticsGraph;
