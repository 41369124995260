import React, {useEffect, useState} from "react";
import {connect} from "react-redux";
import {IStore} from "../redux/defaultStore";
import {Button, Card, CardBody, Container} from "reactstrap";
import cloneDeep from "lodash.clonedeep";
import {
	MarketApi,
	MarketUserGetOrdersForMarketGetOrderTypeEnum,
	MarketUserGetOrdersForMarketGetOrderActiveSelectEnum,
	MarketIdentifier,
	Order, OrderInformation
} from "client";
import SellOrdersTable, {ISellOrderTableRow} from "../Components/SellOrdersTable";
import PostSellOfferModal from "../Components/PostSellOfferModal";
import BuySecuritiesFromSellOrderModal from "../Components/BuySecuritiesFromSellOrderModal";
import {addError, decrementLoading, incrementLoading} from "../redux/meta/MetaActions";
import {getConfig} from "../services/clientApis";
import {IBuyOrderTableRow} from "../Components/BuyOrdersTable";

interface ISellOrdersPageProps {
	token?: string;
	dispatch?: any;
}

const SellOrdersPage: React.FC<ISellOrdersPageProps> = (props: ISellOrdersPageProps) => {

	const {token} = props;
	const [sellOrders, setSellOrders] = useState<Array<ISellOrderTableRow>>();
	const [showCreateOrderModal, setShowCreateOrderModal] = useState(false);
	const [orderToBuySecuritiesFrom, setOrderToBuySecuritiesFrom] = useState<OrderInformation>();

	useEffect(() => {
		readSellOrders().then().catch();
	}, []);

	/**
	 * toggle the post buy order modal
	 *
	 * **/
	function toggleShowCreateOrderModal(): void {
		setShowCreateOrderModal(!showCreateOrderModal);
	}

	/**
	 * When clicking the "Buy Securities" button on one of the table rows,
	 * this function gets called to set the selected order to a state variable,
	 * prompting the modal to open with the form
	 *
	 * @param orderInfo
	 */
	function selectOrderToBuyFrom(orderInfo?: OrderInformation): void {
		if (orderInfo) {
			setOrderToBuySecuritiesFrom(orderInfo);
		} else {
			setOrderToBuySecuritiesFrom(null);
		}
	}

	/**
	 * Called to fetch the data for the sell order table
	 *
	 */
	async function readSellOrders(): Promise<void> {
		props.dispatch(incrementLoading());

		try {
			const res = await new MarketApi(getConfig(token)).marketUserGetOrdersForMarketGet({
				orderActiveSelect: MarketUserGetOrdersForMarketGetOrderActiveSelectEnum.True,
				marketIdentifier: MarketIdentifier.RealEstateSecuritiesPlayMoneyVancouver,
				includeOrderInformation: true,
				orderType: MarketUserGetOrdersForMarketGetOrderTypeEnum.SellOrder,
			});

			const data = cloneDeep(res);
			const dataForTable: Array<ISellOrderTableRow> = [];

			for (const d of data) {
				dataForTable.push({
					raw: d,
					action: selectOrderToBuyFrom,
					name: d.order.security.name,
					expirationDate: d.order.security.expirationDate,
					priceAtExpiration: d.order.security.expectedValueAtExpiration,
					priceAtInitialization: d.order.security.lastUpdatedValue,
					quantity: d.remainingAmount,
					pricePer: d.order.pricePer,
					expectedTrue: d.order.expectedTrue,
				})
			}

			setSellOrders(dataForTable);

		} catch (e) {
			props.dispatch(addError(await e.json()))
		}

		props.dispatch(decrementLoading());
	}

	function onPostedOrder(): void {
		setShowCreateOrderModal(false);
		readSellOrders().then().catch();
	}

	function onAcceptedOrder(): void {
		setOrderToBuySecuritiesFrom(null);
		readSellOrders().then().catch();
	}

	return (
		<React.Fragment>
			<PostSellOfferModal
				isOpen={showCreateOrderModal}
				onCancel={toggleShowCreateOrderModal}
				onDone={onPostedOrder}
			/>

			<BuySecuritiesFromSellOrderModal
				orderInfo={orderToBuySecuritiesFrom}
				onCancel={selectOrderToBuyFrom}
				onDone={onAcceptedOrder}
			/>

			<div className="my-5 px-1 px-lg-2">
				<Card>
					<CardBody>
						<div className="d-flex justify-content-center mb-4">
							<Button color="materialBlue" onClick={toggleShowCreateOrderModal}>
								Post a Sell Order
							</Button>
						</div>
					</CardBody>

					<SellOrdersTable data={sellOrders}/>
				</Card>

			</div>
		</React.Fragment>
	);
};

export default connect((store: IStore, props: ISellOrdersPageProps) => {
	return {
		token: store.metaStore.token,
		...props,
	}
})(SellOrdersPage);
