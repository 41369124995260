export interface IMetaStore {
	token?: any;
	loadingCount: number;
	errors: string[];
	sidebarOpen: boolean;
	toastMessage: {title: string, description: string};
}

export default {
	loadingCount: 0,
	errors: [],
	sidebarOpen: false,
} as IMetaStore
