import React from "react";
import BootstrapTable from 'react-bootstrap-table-next';
import {Order} from "client";
import {
	expectedTrueToAboveBelow,
	formatCurrency,
	momentStringFromMilliseconds,
	numberWithCommas, parseOrderTypeToString
} from "../utils/formatters";
import {
	createTradingTableActionButton, IColumn,
	whiteSpaceCellFormatter
} from "../utils/tableCellHTMLFormatters";

export interface ICurrentOrdersTableRow {
	raw: Order,
	listingType: string;
	propertyType: string;
	expirationDate: Date;
	amountLeft: string;
	pricePerSecurity: number;
	priceAtExpiration: string;
	expectedTrue: boolean;
	action(): void;
}

interface ICurrentOrdersTableProps {
	data: Array<ICurrentOrdersTableRow>
}

const CurrentOrdersTable: React.FC<ICurrentOrdersTableProps> = (props: ICurrentOrdersTableProps) => {

	const {data} = props;

	return (
		<BootstrapTable
			bootstrap4={true}
			hover={true}
			keyField="id"
			data={data ? data : []}
			columns={columns}
			bordered={false}
			condensed={true}
			noDataIndication={data === undefined ? "Loading..." : "You currently have no active orders."}
		/>
	);
};

const columns: Array<IColumn> = [
	{dataField: "listingType", text: "Trade Type", formatter: parseOrderTypeToString, sort: true, classes: "text-capitalize"},
	{dataField: "propertyType", text: "Security", formatter: whiteSpaceCellFormatter, sort: true},
	{dataField: "expirationDate", text: "Expiration Date", formatter: momentStringFromMilliseconds, sort: true, headerAlign: "right", classes: "text-right"},
	{dataField: "amountLeft", text: "Amount Left", formatter: numberWithCommas, sort: true, headerAlign: "right", classes: "text-right"},
	{dataField: "pricePerSecurity", text: "Price", formatter: formatCurrency, sort: true, headerAlign: "right", classes: "text-right"},
	{dataField: "priceAtExpiration", text: "Price At Expiration", formatter: formatCurrency, sort: true, headerAlign: "right", classes: "text-right"},
	{dataField: "expectedTrue", text: "Above/Below Market Value", formatter: expectedTrueToAboveBelow, sort: true, headerAlign: "right", classes: "text-right"},
	{dataField: "action", text: "", formatter: createTradingTableActionButton("Cancel")},
];

export default CurrentOrdersTable;
