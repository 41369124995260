import InitialMetaStore, {IMetaStore} from "./meta/InitialMetaStore";
import InitialFinancialStandingStore, {IFinancialStandingStore} from "./financialStanding/InitialFinancialStandingStore";

export interface IStore {
	metaStore: IMetaStore;
	financialStandingStore: IFinancialStandingStore;
}

export default {
	metaStore: InitialMetaStore,
	financialStandingStore: InitialFinancialStandingStore,
} as IStore;
