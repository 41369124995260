import {IAction, store} from "../index";
import {FinancialStandingType} from "./FinancialStandingReducer";
import {MarketApi, MarketIdentifier} from "client";
import {getConfig} from "../../services/clientApis";

export async function updateSidebarStandings(): Promise<IAction<FinancialStandingType, {availableBalance: number, currentBalance: number}>> {

	try {
		const res = await new MarketApi(getConfig(store.getState().metaStore.token)).marketGetPortfolioGet({marketIdentifier: MarketIdentifier.RealEstateSecuritiesPlayMoneyVancouver});

		return {
			type: FinancialStandingType.SET_VALUES,
			payload: {
				availableBalance: res.availableMoney,
				currentBalance: res.availableMoney + res.investedMoney,
			}
		}
	} catch (e) {
		// console.log("e:", e);
	}

}
