import React from "react";
import {Card} from "reactstrap";
import {ArticleMarket} from "client";

interface IArticleMarketThumbnailDisplayProps {
	market: ArticleMarket;
	active: boolean;
	onSelect(): void;
}

const ArticleMarketThumbnailDisplay: React.FC<IArticleMarketThumbnailDisplayProps> = (props: IArticleMarketThumbnailDisplayProps) => {

	const {market, active} = props;

	return (
		<Card
			onClick={props.onSelect}
			className="w-100 overflow-hidden article-market-item"
			style={{
				borderRadius: 10,
				borderWidth: 2,
				borderStyle: "solid",
				borderColor: active ? "black" : "transparent",
			}}
		>
			<div style={{height: 200}} className="w-100 overflow-hidden">
				<img
					style={{width: "100%", height: "100%", objectFit: "cover"}}
					src={market.thumbnail.url}
					alt={`${market.title}-thumbnail`}
					className="article-market-thumbnail"
				/>
			</div>

			<div className="p-3">
				<h3>
					{market.title}
				</h3>
			</div>
		</Card>
	);
};

export default ArticleMarketThumbnailDisplay;
