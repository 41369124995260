import React, {ReactNode} from "react";
import {Nav, NavItem} from "reactstrap";
import {Link, NavLink} from "react-router-dom";
import {useLocation} from "react-router-dom";

export interface INavTab {
	path: string;
	text: string;
}

interface IPageTitleNavProps {
	title: string;
	children?: ReactNode;
	navTabs?: Array<INavTab>;
}

const PageTitleNav: React.FC<IPageTitleNavProps> = (props: IPageTitleNavProps) => {

	const {title, children, navTabs} = props;
	const location = useLocation();

	function mapNavTabs(tabs: Array<INavTab>): ReactNode {
		return tabs.map((tab: INavTab, i: number) => {

			const active: boolean = location.pathname === tab.path;

			return (
				<NavItem className="mr-3">
					<NavLink
						to={tab.path}
						className={"text-decoration-none text-nowrap nav-link d-flex align-items-center p-0 mb-2 " + (active ? "text-white" : "text-light")}
					>
						{tab.text}
					</NavLink>
					<div style={{height: active ? 3 : 0}} className="bg-white"/>
				</NavItem>
			);
		});
	}

	return (
		<div className="w-100 border-bottom pt-3 px-3 bg-materialBlue">
			<h3 className={"text-white" + ((children || navTabs) ? " mb-3" : "")}>{title}</h3>

			{navTabs && (
				<Nav navbar className="d-flex flex-row" style={{overflowX: "scroll"}}>
					{mapNavTabs(navTabs)}
				</Nav>
			)}

			{children && (
				<React.Fragment>
					{children}
				</React.Fragment>
			)}
		</div>
	);
};

export default PageTitleNav;
