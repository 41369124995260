import React from "react";
import {Card, Col, Row} from "reactstrap";
import {UsefulLink} from "client";

interface IUsefulLinkDisplayProps {
	usefulLink: UsefulLink
}

const UsefulLinkDisplay: React.FC<IUsefulLinkDisplayProps> = (props: IUsefulLinkDisplayProps) => {

	const {usefulLink} = props;

	return (
		<a href={usefulLink.usefulURL} target="_blank" className="h-100 w-100 d-flex text-dark text-decoration-none useful-link-item">
			<Card style={{borderRadius: 10}} className="overflow-hidden">

				{usefulLink.thumbnail && (
					<div style={{width: "100%", height: 240}} className="overflow-hidden">
						<img
							style={{width: "100%", height: "100%", objectFit: "contain"}}
							src={usefulLink.thumbnail.url}
							alt={`${usefulLink.title}-thumbnail`}
							className="useful-link-thumbnail"
						/>
					</div>
				)}

				{(usefulLink.title || usefulLink.description) && (
					<div className="p-3">
						{usefulLink.title && (
							<h3>
								{usefulLink.title}
							</h3>
						)}
						{usefulLink.description && (
							<p>
								{usefulLink.description}
							</p>
						)}
					</div>
				)}
			</Card>
		</a>
	);
};

export default UsefulLinkDisplay;
