import React from "react";
import BootstrapTable from 'react-bootstrap-table-next';
import {formatCurrency, momentStringFromMilliseconds, numberWithCommas} from "../utils/formatters";
import {
	IColumn,
	whiteSpaceCellFormatter
} from "../utils/tableCellHTMLFormatters";
import {Security, SecurityTotal} from "client";

export interface IMarketSecuritiesTableRow {
	raw: SecurityTotal,
	propertyType: string;
	expirationDate: number;
	priceAtExpiration: number;
	aboveMarketSharesIssued: number;
	belowMarketSharesIssued: number;
	totalIssued: number;
}

interface IMarketSecuritiesTableProps {
	data: Array<IMarketSecuritiesTableRow>
}

const MarketSecuritiesTable: React.FC<IMarketSecuritiesTableProps> = (props: IMarketSecuritiesTableProps) => {

	const {data} = props;

	return (
		<BootstrapTable
			bootstrap4={true}
			hover={true}
			keyField="id"
			data={data ? data : []}
			columns={columns}
			bordered={false}
			condensed={true}
			noDataIndication={data === undefined ? "Loading..." : "You currently have no trading history."}
		/>
	);
};

const columns: Array<IColumn> = [
	{dataField: "propertyType", text: "Security", formatter: whiteSpaceCellFormatter, sort: true},
	{dataField: "expirationDate", text: "Expiration Date", formatter: momentStringFromMilliseconds, sort: true, headerAlign: "right", classes: "text-right"},
	{dataField: "priceAtExpiration", text: "Price At Expiration", formatter: formatCurrency, sort: true, headerAlign: "right", classes: "text-right"},
	{dataField: "aboveMarketSharesIssued", text: "Above Market Shares Issued", formatter: numberWithCommas, sort: true, headerAlign: "right", classes: "text-right"},
	{dataField: "belowMarketSharesIssued", text: "Below Market Shares Issued", formatter: numberWithCommas, sort: true, headerAlign: "right", classes: "text-right"},
	{dataField: "totalIssued", text: "Total Issued", formatter: numberWithCommas, sort: true, headerAlign: "right", classes: "text-right"},
];

export default MarketSecuritiesTable;
