import React, {useEffect, useState} from "react";
import {connect} from "react-redux";
import {IStore} from "../redux/defaultStore";
import {Button, Toast, ToastBody, ToastHeader} from "reactstrap";
import {removeToast} from "../redux/meta/MetaActions";
import {formatCurrency, numberWithCommas} from "../utils/formatters";

export enum ToastType {
	POSTED_BUY,
	POSTED_SELL,
	ACCEPTED_BUY,
	ACCEPTED_SELL,
}

/**
 * where value is either dollars or quantity of security
 *
 * @param type
 * @param value
 */
export function getToastMessage(type: ToastType, value: number): { title: string, description: string } {
	switch (type) {
		case ToastType.POSTED_BUY:
			return {title: "Buy Order Listed", description: `You invested ${formatCurrency(value)} in a new buy order.`};
		case ToastType.POSTED_SELL:
			return {title: "Sell Order Listed", description: `${numberWithCommas(value)} of the security has been moved from "owned" to "invested".`};
		case ToastType.ACCEPTED_BUY:
			return {title: "Buy Order Accepted", description: `${formatCurrency(value)} was credited from your available balance.`};
		case ToastType.ACCEPTED_SELL:
			return {title: "Sell Order Accepted", description: `${numberWithCommas(value)} of the security has been transferred to you.`};
	}
}

interface IToastManagerProps {
	toastMessage?: { title: string, description: string };
	dispatch?: any;
}

const ToastManager: React.FC<IToastManagerProps> = (props: IToastManagerProps) => {

	const {toastMessage} = props;
	const [dataClone, setDataClone] = useState<{ title: string, description: string }>();

	useEffect(() => {
		if (toastMessage && Object.keys(toastMessage).length > 0) {
			setDataClone(toastMessage);
		}
	}, [JSON.stringify(toastMessage)]);

	if (toastMessage) {
		setTimeout(() => {
			helper();
		}, 5000);
	}

	function helper(): void {
		props.dispatch(removeToast());
	}

	const isOpen: boolean = toastMessage && Object.keys(toastMessage).length > 0;

	return (
		<React.Fragment>
				<Toast
					isOpen={isOpen === true}
					style={{
						width: 300,
						position: "fixed",
						top: 0,
						right: 0,
						zIndex: 10000,
					}}
					className="m-3"
				>
					<ToastHeader toggle={helper}>
						{dataClone ? dataClone.title : ""}
					</ToastHeader>
					<ToastBody>
						{dataClone ? dataClone.description : ""}
					</ToastBody>
					<div className="d-flex justify-content-end">
						<Button color="link" onClick={helper}>
							Dismiss
						</Button>
					</div>
				</Toast>
		</React.Fragment>
	);
};

export default connect((store: IStore, props: IToastManagerProps) => {
	return {
		...props,
		toastMessage: store.metaStore.toastMessage,
	}
})(ToastManager);
