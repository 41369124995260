import React, {ReactNode, useState} from "react";
import {FinancialPoint, FinancialSummary, Transaction} from "client";
import {formatCurrency} from "../utils/formatters";
import {FiArrowRight} from "react-icons/all";
import moment from "moment";
import {UncontrolledTooltip} from "reactstrap";
import TransactionSummaryModal from "./TransactionSummaryModal";

interface IFinancialSummaryComponentProps {
	financialSummary: FinancialSummary;
}

type BreakdownType =
	"buyOrdersSoldTo"
	| "sellOrdersAccepted"
	| "buyOrdersAccepted"
	| "sellOrdersPurchased"
	| "deposits"
	| "withdrawals";

const tooltips: { [key: string]: string } = {
	deposits: "The money that has been deposited from external sources.",
	withdrawals: "The money that has been withdrawn from the exchange.",
	buyOrdersSoldTo: "The money that has been gained by selling securities to buy orders.",
	sellOrdersAccepted: "The money that has been gained by listing sell orders and selling securities.",
	buyOrdersAccepted: "The money spent on listing buy orders and buying securities.",
	sellOrdersPurchased: "The money spent on buying securities from sell orders.",
	investedInBuyOrders: "The money that is sitting in your current buy orders. This money is held up because it needs to be available when/if the buy order is accepted by another user.",
	openingBalance: "The money that you had at 12:00am on the start date.",
	closingBalance: "The money that is free to be used in trading or cashed out as of 11:59 pm on the selected end date, or the current time if the end date is set to today.",
	profit: "The sum of 'available balance', 'invested balance', and 'withdrawals' subtracted from the `deposits`. This indicates all of the money made in comparison to the money put into the trading system.",
};

const FinancialSummaryComponent: React.FC<IFinancialSummaryComponentProps> = (props: IFinancialSummaryComponentProps) => {

	const {
		buyOrdersSoldTo,
		totalBuyOrdersSoldTo,
		sellOrdersAccepted,
		totalSellOrdersAccepted,
		buyOrdersAccepted,
		totalBuyOrdersAccepted,
		sellOrdersPurchased,
		totalSellOrdersPurchased,
		deposits,
		totalDeposits,
		withdrawals,
		totalWithdrawals,
		investedInBuyOrders,
		openingBalance,
		closingBalance,
		profit,
	} = props.financialSummary;

	const [selectedBreakdown, setSelectedBreakdown] = useState<BreakdownType>();
	const [transactionToView, setTransactionToView] = useState<Transaction>();

	function selectBreakdown(breakdown: BreakdownType): () => void {
		return () => {
			setSelectedBreakdown(selectedBreakdown !== breakdown ? breakdown : null);
		}
	}

	function createBreakdown(bd: Array<FinancialPoint> = []): ReactNode {
		if (bd.length < 1) {
			return (<div><p className="text-center font-italic text-muted">No data points.</p></div>);
		}

		return bd.map((p: FinancialPoint, i: number) => {

			function onClickHelper(): void {
				selectFinancialPoint(p.transaction);
			}

			const uniqueId: string = "breakdown-item-" + i;

			return (
				<div id={uniqueId} className="breakdown-item" onClick={onClickHelper} key={`breakdown-item-${i}`}>
					<span>
						{moment(p.transaction.date).format("MMM Do YYYY")}
					</span>

					<span className={p.amount >= 0 ? "text-success" : "text-danger"}>
						{/*<h6>{p.amount >= 0 ? formatCurrency(p.amount) : `(${formatCurrency(profit)})`}</h6>*/}
						<h6>{formatCurrency(p.amount)}</h6>
					</span>

					<UncontrolledTooltip placement="top" target={uniqueId}>
						{p.transaction.transactionType}
					</UncontrolledTooltip>
				</div>
			);
		});
	}

	function selectFinancialPoint(f: Transaction = null): void {
		setTransactionToView(f);
	}

	return (
		<div style={{overflowX: "scroll"}}>
			<TransactionSummaryModal transaction={transactionToView} onClose={selectFinancialPoint}/>

			<div className="d-flex" style={{minWidth: 740}}>
				<div style={{width: "100%"}}>
					<div id="opening_balance" className="clickable-row-base">
						<span className="cr-label-span">
							<h5>Opening Balance</h5>
						</span>
						<span className="cr-value-span">
							<h5>
								{formatCurrency(openingBalance)}
								<FiArrowRight style={{maxHeight: 50, fontSize: "1.75rem", color: "transparent"}}
								              className="ml-2"/>
							</h5>
						</span>

						<UncontrolledTooltip placement="right" target="opening_balance">
							{tooltips.openingBalance}
						</UncontrolledTooltip>
					</div>

					<hr className="my-3"/>

					{(totalDeposits !== undefined) && (totalWithdrawals !== undefined) && (
						<React.Fragment>
							<div id="deposits"
							     className={selectedBreakdown === "deposits" ? "selected-row" : "clickable-row"}
							     onClick={selectBreakdown("deposits")}>
								<span className="cr-label-span">
									<h5>Deposit(s)</h5>
								</span>
								<span className="cr-value-span">
									<h5 className="text-success">
										{formatCurrency(totalDeposits)}
										<FiArrowRight style={{maxHeight: 50, fontSize: "1.75rem"}}
										              className="text-dark ml-2"/>
									</h5>
								</span>

								<UncontrolledTooltip placement="right" target="deposits">
									{tooltips.deposits}
								</UncontrolledTooltip>
							</div>

							<div id="withdrawals"
							     className={selectedBreakdown === "withdrawals" ? "selected-row" : "clickable-row"}
							     onClick={selectBreakdown("withdrawals")}>
								<span className="cr-label-span">
									<h5>Withdrawal(s)</h5>
								</span>
								<span className="cr-value-span">
									<h5 className="text-danger">
										({formatCurrency(totalWithdrawals)})
										<FiArrowRight style={{maxHeight: 50, fontSize: "1.75rem"}}
										              className="text-dark ml-2"/>
									</h5>
								</span>

								<UncontrolledTooltip placement="right" target="withdrawals">
									{tooltips.withdrawals}
								</UncontrolledTooltip>
							</div>

							<hr className="my-3"/>
						</React.Fragment>
					)}

					<div id="buy_orders_sold_to"
					     className={selectedBreakdown === "buyOrdersSoldTo" ? "selected-row" : "clickable-row"}
					     onClick={selectBreakdown("buyOrdersSoldTo")}>
						<span className="cr-label-span">
							<h5>Buy order(s) sold to</h5>
						</span>
						<span className="cr-value-span">
							<h5 className="text-success">
								{formatCurrency(totalBuyOrdersSoldTo)}
								<FiArrowRight style={{maxHeight: 50, fontSize: "1.75rem"}} className="text-dark ml-2"/>
							</h5>
						</span>

						<UncontrolledTooltip placement="right" target="buy_orders_sold_to">
							{tooltips.buyOrdersSoldTo}
						</UncontrolledTooltip>
					</div>

					<div id="sell_orders_accepted"
					     className={selectedBreakdown === "sellOrdersAccepted" ? "selected-row" : "clickable-row"}
					     onClick={selectBreakdown("sellOrdersAccepted")}>
						<span className="cr-label-span">
							<h5>Sell order(s) accepted</h5>
						</span>
						<span className="cr-value-span">
							<h5 className="text-success">
								{formatCurrency(totalSellOrdersAccepted)}
								<FiArrowRight style={{maxHeight: 50, fontSize: "1.75rem"}} className="text-dark ml-2"/>
							</h5>
						</span>

						<UncontrolledTooltip placement="right" target="sell_orders_accepted">
							{tooltips.sellOrdersAccepted}
						</UncontrolledTooltip>
					</div>

					<div id="bids_accepted"
					     className={selectedBreakdown === "buyOrdersAccepted" ? "selected-row" : "clickable-row"}
					     onClick={selectBreakdown("buyOrdersAccepted")}>
						<span className="cr-label-span">
							<h5>Bid(s) accepted</h5>
						</span>
						<span className="cr-value-span">
							<h5 className="text-danger">
								({formatCurrency(totalBuyOrdersAccepted)})
								<FiArrowRight style={{maxHeight: 50, fontSize: "1.75rem"}} className="text-dark ml-2"/>
							</h5>
						</span>

						<UncontrolledTooltip placement="right" target="bids_accepted">
							{tooltips.buyOrdersAccepted}
						</UncontrolledTooltip>
					</div>

					<div id="sell_orders_purchased"
					     className={selectedBreakdown === "sellOrdersPurchased" ? "selected-row" : "clickable-row"}
					     onClick={selectBreakdown("sellOrdersPurchased")}>
						<span className="cr-label-span">
							<h5>Sell order(s) purchased</h5>
						</span>
						<span className="cr-value-span">
							<h5 className="text-danger">
								({formatCurrency(totalSellOrdersPurchased)})
								<FiArrowRight style={{maxHeight: 50, fontSize: "1.75rem"}} className="text-dark ml-2"/>
							</h5>
						</span>

						<UncontrolledTooltip placement="right" target="sell_orders_purchased">
							{tooltips.sellOrdersPurchased}
						</UncontrolledTooltip>
					</div>

					<hr className="my-3"/>

					<div id="invested_in_buy_orders" className="clickable-row-base">
						<span className="cr-label-span">
							<h5>Invested in buy orders</h5>
						</span>
						<span className="cr-value-span">
							<h5>
								({formatCurrency(investedInBuyOrders)})
								<FiArrowRight style={{maxHeight: 50, fontSize: "1.75rem", color: "transparent"}}
								              className="ml-2"/>
							</h5>
						</span>

						<UncontrolledTooltip placement="right" target="invested_in_buy_orders">
							{tooltips.investedInBuyOrders}
						</UncontrolledTooltip>
					</div>

					<div id="available_balance" className="clickable-row-base">
						<span className="cr-label-span">
							<h5>Closing Balance</h5>
						</span>
						<span className="cr-value-span">
							<h5>
								{formatCurrency(closingBalance)}
								<FiArrowRight style={{maxHeight: 50, fontSize: "1.75rem", color: "transparent"}}
								              className="ml-2"/>
							</h5>
						</span>

						<UncontrolledTooltip placement="right" target="available_balance">
							{tooltips.closingBalance}
						</UncontrolledTooltip>
					</div>

					{profit !== undefined && (
						<React.Fragment>
							<hr className="my-3"/>

							<div id="financial_summary_profit" className="clickable-row-base">
								<span className="cr-label-span">
									<h5>Profit</h5>
								</span>
								<span className="cr-value-span">
									<h5 className={profit >= 0 ? "text-success" : "text-danger"}>
										{profit >= 0 ? formatCurrency(profit) : `(${formatCurrency(profit)})`}
										<FiArrowRight style={{maxHeight: 50, fontSize: "1.75rem", color: "transparent"}}
										              className="ml-2"/>
									</h5>
								</span>

								<UncontrolledTooltip placement="right" target="financial_summary_profit">
									{tooltips.profit}
								</UncontrolledTooltip>
							</div>
						</React.Fragment>
					)}

				</div>

				<div style={{width: "100%", maxWidth: 300, maxHeight: 543, overflowY: "scroll"}} className="px-3">
					{selectedBreakdown && (
						<React.Fragment>
							<h5 className="text-center">
								{getBreakdownTitle(selectedBreakdown)}
							</h5>
							<p className="mb-0 text-center text-muted">
								{tooltips[selectedBreakdown]}
							</p>
							<div className="w-100 px-5">
								<hr className="my-3"/>
							</div>

							{createBreakdown(props.financialSummary[selectedBreakdown] as Array<FinancialPoint>)}
						</React.Fragment>
					)}
				</div>
			</div>
		</div>
	);
};

function getBreakdownTitle(type: BreakdownType): string {
	switch (type) {
		case "buyOrdersSoldTo":
			return "Buy Orders Sold To";
		case "sellOrdersAccepted":
			return "Sell Orders Accepted";
		case "buyOrdersAccepted":
			return "Buy Orders Accepted";
		case "sellOrdersPurchased":
			return "Sell Orders Purchased";
		case "deposits":
			return "Deposits";
		case "withdrawals":
			return "Withdrawals";
		default:
			return "Breakdown";
	}
}

export default FinancialSummaryComponent;
