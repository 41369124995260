import React, {useEffect, useState} from "react";
import {connect} from "react-redux";
import {IStore} from "../redux/defaultStore";
import {Card, CardBody} from "reactstrap";
import {addError, decrementLoading, incrementLoading} from "../redux/meta/MetaActions";
import TradingHistoryTable, {ITradingHistoryTableRow} from "../Components/TradingHistoryTable";
import {MarketApi, MarketIdentifier} from "client";
import {getConfig} from "../services/clientApis";
import cloneDeep from "lodash.clonedeep";

interface IMyTradingHistoryProps {
	token?: string;
	dispatch?: any;
}

const MyTradingHistory: React.FC<IMyTradingHistoryProps> = (props: IMyTradingHistoryProps) => {

	const {token} = props;
	const [tradeHistory, setTradeHistory] = useState<Array<ITradingHistoryTableRow>>();

	useEffect(() => {
		readTradeHistory().then().catch();
	}, []);

	/**
	 * Call api to fetch the trading history
	 *
	 */
	async function readTradeHistory(): Promise<void> {
		props.dispatch(incrementLoading());

		try {
			const res = await new MarketApi(getConfig(token)).marketGetUsersTradingHistoryGet({marketIdentifier: MarketIdentifier.RealEstateSecuritiesPlayMoneyVancouver});

			const data = cloneDeep(res.trades);
			const dataForTable: Array<ITradingHistoryTableRow> = [];

			for (const d of data) {
				dataForTable.push({
					raw: d,
					date: d.date,
					action: d.transaction.transactionType,
					listingType: d.orderType,
					propertyType: d.security.name,
					pricePerSecurity: d.order.pricePer,
					expectedTrue: d.expectedTrue,
					quantity: d.quantity,
					moneyTraded: d.moneyTraded,
					expirationDate: d.expirationDate,
				});
			}

			setTradeHistory(dataForTable);

		} catch (e) {
			props.dispatch(addError(await e.json()))
		}

		props.dispatch(decrementLoading());

	}

	return (
		<div>
			<div className="my-5 px-1 px-lg-2">
				<Card>
					<CardBody>
						<h2 className="mb-4">
							My Trading History
						</h2>
					</CardBody>

					<TradingHistoryTable data={tradeHistory}/>
				</Card>
			</div>
		</div>
	);
};

export default connect((store: IStore, props: IMyTradingHistoryProps) => {
	return {
		token: store.metaStore.token,
		...props,
	}
})(MyTradingHistory);
